<div class="">
    <div class="top-0 rounded-lg my-4 h-full">
        <div class="primaryColor dark:text-[hsl(215,20%,65%)] mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <form (ngSubmit)="changePassword()">
                <span class="my-4">
                    <p class="deatailsHead mb-2 ml-2 font-semibold whitespace-nowrap min-w-[160px]"
                        id="ssetting_label_currentpass">Current password</p>
                    <span class="relative">
                        <input maxlength="16" ref="currentPassword" [type]="inputType" [(ngModel)]="currentPassword"
                            name="currentPassword" id="ssetting_input_currentpass`" autocomplete="off"
                            class="text-base rounded border border-gray-300 pl-4 leading-6 w-[320px] dark:bg-zinc-800"
                            placeholder="Enter current password"
                            [ngClass]="submitted && !currentPassword ? 'is-invalid' : '' "
                            (keypress)="common._.removeSpace($event)" (input)="handleInput()">
                        <div (click)="passShowHide(1)"
                            class="absolute right-3 bottom-1 flex items-center cursor-pointer z-50 opacity-90">
                            <span *ngIf="isPassword">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                </svg>
                            </span>
                            <span *ngIf="!isPassword">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </span>
                        </div>
                    </span>
                </span>

                <div class=" my-4">
                    <p class="deatailsHead mb-2 ml-2 font-semibold whitespace-nowrap min-w-[160px]"
                        id="ssetting_label_confirmpass">New password</p>
                    <span class="relative">
                        <input maxlength="16" ref="newPassword" [type]="inputType1" [(ngModel)]="newPassword"
                            name="newPassword" id="ssetting_input_confirmpass`" autocomplete="off"
                            class="text-base rounded border border-gray-300 pl-4 leading-6 w-[320px] dark:bg-zinc-800"
                            placeholder="Enter new password" [ngClass]="submitted && !newPassword  ? 'is-invalid':''"
                            (keypress)="common._.removeSpace($event)" (input)="handleInput()">
                        <div (click)="passShowHide(2)"
                            class="absolute right-3 bottom-1 flex items-center cursor-pointer z-50 opacity-90">
                            <span *ngIf="isPassword1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                </svg>
                            </span>
                            <span *ngIf="!isPassword1">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </span>
                        </div>
                    </span>
                </div>

                <div class="my-4">
                    <p class="deatailsHead mb-2 ml-2 font-semibold whitespace-nowrap min-w-[160px]"
                        id="_current_label_newpass">Confirm password</p>
                    <span class="relative">
                        <input maxlength="16" ref="confirmPassword" [type]="inputType2" [(ngModel)]="confirmPassword"
                            name="confirmPassword" id="ssetting_input_newpass`" autocomplete="off"
                            class="text-base rounded border border-gray-300 pl-4 leading-6 w-[320px] dark:bg-zinc-800"
                            placeholder="Enter confirm password"
                            [ngClass]="submitted && !confirmPassword  ? 'is-invalid':''"
                            (keypress)="common._.removeSpace($event)" (input)="handleInput()">
                        <div (click)="passShowHide(3)"
                            class="absolute right-3 bottom-1 flex items-center cursor-pointer z-50 opacity-90">
                            <span *ngIf="isPassword2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                </svg>
                            </span>
                            <span *ngIf="!isPassword2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </span>
                        </div>
                    </span>
                </div>

                <div class="h-2 mt-2">
                    <p *ngIf="submitted && changePassErrorMsg != ''" class="text-sm text-red-500 pt-1">{{
                        changePassErrorMsg }}</p>
                </div>

                <div class="my-8 flex justify-end max-w-[320px]">
                    <button type="submit"
                        class="bg-[#243154] text-sm border border-[#243154] rounded-lg text-white px-10 py-2 h-[36px]">

                        <span *ngIf="!getIsLoader">Sumbit</span>
                        <app-custom-icon *ngIf="getIsLoader" [item]="'loader'" [style]="'height:24px'"
                            class="flex px-3 items-center ml-2 justify-center"></app-custom-icon>
                    </button>
                </div>
            </form>
            <div class="px-3">
                <div class="secondaryColor text-sm mb-4">Password policy</div>
                <ul class="pl-4">
                    <li class="mb-2 primaryColor text-sm">&#x2022;&nbsp;&nbsp; Password must contain max 16 characters
                    </li>
                    <li class="mb-2 primaryColor text-sm">&#x2022;&nbsp;&nbsp; Password must contain min 8 characters
                    </li>
                    <li class="mb-2 primaryColor text-sm">&#x2022;&nbsp;&nbsp; Password must contain 1 number</li>
                    <li class="mb-2 primaryColor text-sm">&#x2022;&nbsp;&nbsp; Password must contain 1 uppercase letter
                    </li>
                    <li class="mb-2 primaryColor text-sm">&#x2022;&nbsp;&nbsp; Password must contain 1 lowercase letter
                    </li>
                    <li class="mb-2 primaryColor text-sm">&#x2022;&nbsp;&nbsp; Password must contain 1 Special
                        character</li>
                </ul>
            </div>
        </div>
    </div>
</div>