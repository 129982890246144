import { Component } from '@angular/core';
import { ActivatedRoute, NavigationStart, NavigationEnd, NavigationError, Router } from '@angular/router';
import { ApiServiceService } from '../providers/api-service.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  token:any;
  changeLoader:boolean = false;
  currentRoute:any;
  password:any;
  submitted:boolean = false
  constructor(private router: Router, private api:ApiServiceService, private toaster:ToastrService, private common:CommonService) {
    this.token = this.router?.url.split('/')[2]
  }
  
  ngOnInit() {  
  }

  async resetPassword(val: any) {
    this.submitted = true
    let reset_obj={
      token:this.token,
      newPassword:val
    }
    if(this.password && this.token){
      this.changeLoader = true
      this.api.resetPassword(reset_obj).subscribe(resp => {
        if(resp.status == true){
          this.toaster.success(resp.data, 'Success', {
            timeOut: 5000, closeButton: true,
          });
        this.common.unauthorizeHandle('','','logout')
        this.changeLoader = false
        }

      },(err) => {
        this.common.statusCodeCheck(err)
        this.changeLoader = false
      }).add(() => {
      })
    }
  }
}
