import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as echarts from 'echarts';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, AfterViewInit {
  @Input() getId:any
  @Input() chartData:any
  @Input() chartTotCount:any
  private chart1: any;
  option:any

  ngOnInit() {}

  ngAfterViewInit() {

    this.chart1 = echarts.init(document.getElementById(this.getId));
    if(this.getId != 'chart3'){
      const customColors = ['#62DF8E', '#033365', '#F22119'];
    this.option = {
      tooltip: {
        trigger: 'item',
      },
      legend: {
        bottom: '0%',
        left: 'center',
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          bottom:'10%',
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2,
            color: function(params:any) {               
              return customColors[params.dataIndex];
            }
          },
          label: {
            show: true,
            position: 'center',
            fontSize: 20,
            formatter: `${'Total'}\n\n${this.chartTotCount}`
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: 'bold',
              
            },
          },
          labelLine: {
            show: false,
          },
          data:this.chartData
        },
      ],
    };

    this.chart1.setOption(this.option);
    }else{
      const colors = ['#3B82F6', '#3B82F6'];
      var emphasisStyle = {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0,0,0,0.3)'
        }
      };
      const option = {
        tooltip: {},
        xAxis: {
          data: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
          name: 'X Axis',
          axisLine: { onZero: true },
          splitLine: { show: false },
          splitArea: { show: false }
        },
        yAxis: {
          type: 'value'
        },
        grid: {
          bottom: 30
        },
        series: [
          {
            name: 'Self',
            type: 'line',
            stack: 'one',
            emphasis: emphasisStyle,
            data:this.chartData,
            itemStyle: { color: colors[1] }
          },
        ]
      };
  
      // Initialize the chart with the container element
      this.chart1 = echarts.init(document.getElementById(this.getId));
  
      // Set the option for the chart
      this.chart1.setOption(option);
  
      // Add 'brushSelected' event listener
      this.chart1.on('brushSelected', (params: any) => {
        var brushed = [];
        var brushComponent = params.batch[0];
        for (var sIdx = 0; sIdx < brushComponent?.selected?.length; sIdx++) {
          var rawIndices = brushComponent?.selected[sIdx].dataIndex;
          brushed.push('[Series ' + sIdx + '] ' + rawIndices.join(', '));
        }
        this.chart1.setOption({
          title: {
            backgroundColor: '#333',
            text: 'SELECTED DATA INDICES: \n' + brushed.join('\n'),
            bottom: 0,
            right: '10%',
            width: 100,
            textStyle: {
              fontSize: 12,
              color: '#fff'
            }
          }
        });
      });
    }
  }

}
