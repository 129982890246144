import { NgModule ,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomIconComponent } from './custom-icon/custom-icon.component';
import { HeaderComponent } from './header/header.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { FAQComponent } from './faq/faq.component';
import { ChartComponent } from './chart/chart.component';
import { DragandDropComponent } from './dragand-drop/dragand-drop.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SecuritySettingComponent } from './security-setting/security-setting.component';
import { PaginationComponent } from './pagination/pagination.component';
import { CdBalanceComponent } from './cd-balance/cd-balance.component';


@NgModule({
  exports: [
   SecuritySettingComponent,CustomIconComponent,HeaderComponent,UserDetailsComponent,FAQComponent, ChartComponent, DragandDropComponent,
    PaginationComponent, CdBalanceComponent
  ],
  declarations: [
    CustomIconComponent,
    HeaderComponent,
    UserDetailsComponent,
    FAQComponent,
    ChartComponent,
    DragandDropComponent,
    SecuritySettingComponent,
    PaginationComponent,
    CdBalanceComponent,
  ],
  imports: [
    CommonModule, NgxDropzoneModule,FormsModule
  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers:[  ]
})
export class CommonComponentsModule { }
