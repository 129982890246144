import { Component } from '@angular/core';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, NavigationStart } from '@angular/router';
import { CommonService } from '../common.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardComponent implements CanActivate {

  constructor(private router: Router,private common :CommonService) { }
  async canActivate(): Promise<boolean | UrlTree> {    
    const isAuthenticated =(localStorage.getItem('token')!) ? true : false
    if (isAuthenticated) {
      return true;
    } else {
      return this.router.parseUrl('/')
    }
  }
}
