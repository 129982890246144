<div class="grid grid-cols-1 sm:grid-cols-2 h-screen">
    <div class="flex flex-col justify-center items-center">
        <img src="../../../assets/images/loginLogo.svg" alt="loginLogo" class="h-auto max-w-full">
        <div class="my-2">
            <p class="primaryColor text-xl font-semibold">Welcome to GMC</p>
        </div>

        <div class="my-2">
            <p class="text-center max-w-[380px] p-4 secondaryColor text-base leading-6">Simplify insurance claims with
                our app. File claims effortlessly and get quick reimbursements.</p>
        </div>
    </div>
    <div class="flex flex-col justify-center items-center">
        <div class="max-w-[380px] w-full p-8 mx-auto login-card">
            <form (ngSubmit)="resetPassword(password)">

                <p class="my-2 primaryBlack text-[28px] font-semibold">New Password</p>
                <p class="pb-1 secondaryColor text-sm">Enter your Password</p>
                <div class="pt-4">
                    <p class="pb-1 secondaryColor text-sm">New Password</p>
                    <input type="text" ref="password" class="login-input" [(ngModel)]="password"  [ngModelOptions]="{standalone: true}">
                </div>
                <p class="text-red-500 text-xs pt-2" *ngIf="!password && submitted">password is required</p>
               
                <div class="mt-8">
                    <button type="submit" class="loginbutton-app-bg rounded-full w-full" [disabled]="changeLoader">
                        <span *ngIf="!changeLoader">Verify</span>
                        <app-custom-icon *ngIf="changeLoader" [item]="'loader'" [style]="'height:35px'" class="flex items-center justify-center"></app-custom-icon>
                        
                    </button>
                </div>
                </form>

        </div>

        <!-- footer -->
        <div class="my-8">
            <p class="text-center max-w-[380px] p-4 secondaryColor text-[10px] leading-5">
                <!-- HDFC ERGO General Insurance Company Limited.<br />
                IRDAI Reg No : 146 CIN: U66030MH2007PLC177117 <br />
                1st Floor, HDFC House, 165-166 Backbay Reclamation, H. T. Parekh <br />
                Marg, Churchgate, Mumbai - 400 020 -->
                GoDB Tech Private Limited, TVH Agnitio Park, Rajiv Gandhi Salai, Perungudi, Chennai, Tamil Nadu 600096

            </p>
        </div>
    </div>
</div>