import { Component, Input ,SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-custom-icon',
  templateUrl: './custom-icon.component.html',
  styleUrls: ['./custom-icon.component.scss']
})
export class CustomIconComponent {
  @Input() item :any; 
  @Input() color :any; 
  @Input() style :any; 
  @Input() stroke :any;
}
