import { EventEmitter, Injectable } from '@angular/core';
import { ApiServiceService } from '../providers/api-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimService {
  //claims
  claimsLoader: boolean = false;
  claimListData: any = {};
  filteredClaimList: any = {};
  InitiateClaim: Boolean = false;
  InitiateClaimData: any = [];
  claimStatus: boolean = false;
  viewClaimDeatails: any = '';
  viewClaimDocumentList: any = '';
  claimRaiseQuery: boolean = false;
  claimRespondQuery: boolean = false;
  claimQuestionArrary: any = [];
  claimAnswerArray: any = [];
  claimRaiseQueryTotal: any = [];
  employeeClaimData: any = {};
  uploadedDocument: any = [];
  initiateLoader: boolean = false;
  showDialog: boolean = false;
  raiseQuryClaimData: any = [];
  claim_chart_data: EventEmitter<any> = new EventEmitter();
  constructor(public api: ApiServiceService, public toaster: ToastrService, private router: Router, public common: CommonService) { }

  async emp_claim_list() {
    this.api.getClaimList().subscribe({
      next: async resp => {
        if (resp.status == true) {
          this.claimListData = resp.data
          let statusList = ['rejected', 'query', "pending", 'approved', 'settled']
          let statusObj: any = {}
          statusList.forEach((el, id) => {
            statusObj[el] = []
          });
          if (this.claimListData.length != 0) {
            await this.claimListData.forEach((el: { status: any; }) => {
              for (const key in statusObj) {
                if (key == (el.status).toLowerCase()) {
                  statusObj[key].push(el)
                }
              }
            })
          }
          this.filteredClaimList = statusObj
          this.claim_chart_data.emit(statusObj)
        }
      },
      error: err => {
        this.common.statusCodeCheck(err)
      },
      complete: () => { }
    })
  }

  //This Api to view the document list details of claim
  getClaimDetais(claim_id: any) {
    if (claim_id) {
      this.api.claimDetais(claim_id).subscribe({
        next: async resp => {
          if (resp.status) {
            this.viewClaimDeatails = resp.data
            this.claimStatus = true
          }
        },
        error: err => {
          this.common.statusCodeCheck(err)
        },
        complete: () => {

        }
      })
    }
  }


  //This Api to view the document list details of claim
  getClaimDocumentsList(claim_id: any) {
    if (claim_id) {
      this.viewClaimDocumentList = []
      this.api.claimDocumentList(claim_id).subscribe({
        next: async resp => {
          if (resp.status) {
            this.viewClaimDocumentList = resp.data
            this.claimStatus = true
          }
        },
        error: err => {
          this.common.statusCodeCheck(err)
        },
        complete: () => {

        }
      })
    }
  }

  //This Api for raiseClaimQuery
  claimQueryRaise(val: any) {
    this.claimsLoader = true;
    if (val) {
      let json = {
        claim_id: this.viewClaimDeatails[0]?.claim_id,
        remarks: {
          remarks: val
        }
      }
      this.api.raiseClaimQuery(json).subscribe({
        next: async resp => {
          this.raiseQuryClaimData = resp;
          this.toaster.success(resp.data, 'Success', {
            timeOut: 3000, closeButton: true,
          });
        },
        error: err => {
          this.common.statusCodeCheck(err)
        },
        complete: () => {
      
        }
      }).add(() => {
        this.claimRaiseQuery = false;
        this.claimsLoader = false;
        this.claimStatus = false;
      })
    }
  }

  //This Api for get queryClaim List
  getClaimQueryList(val: any) {
    this.api.claimQueryList(val).subscribe({
      next: async resp => {
        this.claimRaiseQueryTotal = resp?.data
        let questArr: any = []
        let ansArr: any = []
        resp.data.map((t: any) => {
          if (t.type == 'question') {
            questArr.push({"remarks":t.remarks, "c_ts": t.c_ts})
        } else if (t.type == 'answer') {
            ansArr.push({"remarks":t.remarks, "c_ts": t.c_ts})
        }
        })
        this.claimQuestionArrary = questArr
        this.claimAnswerArray = ansArr
        this.claimRaiseQuery = false

      },
      error: err => {
        this.common.statusCodeCheck(err)
      },
      complete: () => {
        this.claimRaiseQuery = false
      }
    })
  }

  // submit respond query document
  async respondQueryDocument(val: any, file: any) {
    this.claimsLoader = true;
    let json = {claim_id:val.claim_id,remarks: {
      remarks: val.desc
    }}
    this.api.raiseClaimQuery(json).subscribe({
      next: resp => {
        if (resp.status == true) {
          this.toaster.success(resp.data, 'Success', {
            timeOut: 5000, closeButton: true,
          });
        }
      },
      error: err => {
        this.common.statusCodeCheck(err)
      },
      complete: () => {
        if(file) {
          this.api.QueryDocumentUpload(val, file).subscribe(resp => {

          }, (err)=> {
            this.common.statusCodeCheck(err)
          })
        }
      }
    }).add(() => {
      this.claimRespondQuery = false;
      this.claimsLoader = false;
      this.claimStatus = false;
    })
  }

  //api used to initiate claim and on succes to upload document.
  employeeClaim(data: any, Files: FormData) {
    this.initiateLoader = true
    this.api.employeeClaim(data).subscribe({
      next: resp => {
        if (resp.satus == true) {
          this.employeeClaimData = resp
          this.toaster.success(resp.data, 'Success', {timeOut: 6000, closeButton: true,});
        }
        this.initiateLoader = false
      },
      error: err => {
        this.initiateLoader = false
        this.common.statusCodeCheck(err)
      },
      complete: () => {
        if (this.uploadedDocument && this.uploadedDocument.length > 0) {
          this.uploadedDocument.forEach((element: any) => {
            if (element.files) {
              let formData = new FormData();
              formData.append('Files', element.files);
              let json = {
                desc: element.desc,
                claim_id: this.employeeClaimData?.request_id,
                Files: formData
              }
              this.initiateDocumentUpload(json);
            }
          });
        }
      
        this.InitiateClaim = false;
        this.InitiateClaimData = []
      }
    })
  }

  //api used to upload document for initiate claim.
  initiateDocumentUpload(data: any) {
    this.api.claimDocumentsUpload(data).subscribe({
      next: resp => {
        if (resp.status == true) {
          this.toaster.success(resp.data, 'Success', {
            timeOut: 3000, closeButton: true,
          });
        }
      },
      error: err => {
        this.common.statusCodeCheck(err)
      },
      complete: () => {
        this.uploadedDocument = []
      }
    })
  }


  //approve or reject claims
  async getApproveOrRejectClaim(payload: any) {
    this.claimsLoader = true;
    this.api.approveOrRejectClaim(payload).subscribe({
      next: resp => {
        if (resp.status) {
          this.toaster.success(resp.data, 'Success', { timeOut: 3000, closeButton: true, });
        }
      },
      error: err => {
        this.common.statusCodeCheck(err)
      },
      complete: () => {

      }
    }).add(() => {
      this.emp_claim_list()
      this.showDialog = false;
      this.claimsLoader = false;
      this.claimStatus = false
    })
  }

  //settle or reject claims
  async getSettleOrRejectClaim(payload: any) {
    this.claimsLoader = true;
    this.api.settleOrRejectClaim(payload).subscribe({
      next: resp => {
        if (resp.status) {
          this.toaster.success(resp.data, 'Success', { timeOut: 3000, closeButton: true, });
        }
      },
      error: err => {
        this.common.statusCodeCheck(err)
      },
      complete: () => {

      }
    }).add(() => {
      this.emp_claim_list()
      this.showDialog = false;
      this.claimsLoader = false;
      this.claimStatus = false
    })
  }
}
