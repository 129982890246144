import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClaimService } from '../../claims/claim.service';
import { EmployerService } from '../../employer/employer.service';
import { LoginService } from '../../login/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() headerData: any;
  @Input() accDropDown: any;
  @Input() myAccount: any
  showDialog: boolean = false;
  openMenu: boolean = false
  empType: any;
  ngOnInit(): void {
    this.empType = JSON.parse(localStorage.getItem('profiledetails')!)
  }
  myAccDropDown: boolean = false

  constructor(public router: Router, public login: LoginService, public claims: ClaimService, public employer: EmployerService) {
  }
  onButtonFocus(val: any) {
    this.headerData.forEach((button: { id: any; isDropdownVisible: boolean; }) => {
      if (button.id === val) {
        button.isDropdownVisible = !button.isDropdownVisible;
      } else {
        button.isDropdownVisible = false;
      }
    });
  }
  onButtonBlur(val: any) {
    this.headerData.forEach((button: { id: any; isDropdownVisible: boolean; }) => {
      if (button.id === val) {
        setTimeout(() => {
          button.isDropdownVisible = false;
        }, 200);
      }
    });
  }
  navigateToHome() {
    this.empType.role == 'Employee' ? this.router.navigateByUrl('/employeeportal/dash') : this.empType.role == 'Employer' ? this.router.navigateByUrl('/employerportal/dash') : ''
  }

  accountNavigation(val: any) {
    this.router.navigateByUrl(val)
    this.openMenu = false
  }
  navigate(val: any, tab: any) {
    if (val == "/employerportal/intimateclaim" || val == "/employeeportal/intimateclaim") {
      this.claims.InitiateClaim = false;
      this.claims.InitiateClaimData = {}
    } else if (val == "/employerportal/claimstatus" || val == "/employeeportal/claimstatus") {
      this.claims.claimStatus = false;
    } else if (val == "/employerportal/endorsements" || val == "/employeeportal/endorsements") {
      this.employer.raiseQuery = false;
    }
    this.router.navigateByUrl(val)
    this.openMenu = false
  }
  navigateToEmployees() {
    this.router.navigateByUrl('/employerportal/employeerecords')
  }
  onConfirm() {
    this.router.navigateByUrl('/')
    localStorage.clear()
    this.login.loginForm.reset()
  }
  onCancel() {
    this.showDialog = false;
  }
  navigateToNetHospital() {
    let empType: any = JSON.parse(localStorage.getItem('profiledetails')!)
    if (empType.role == 'Employee') {
      return this.router.navigateByUrl('/employeeportal/hospitallist')
    } else if (empType.role == 'Employer') {
      return this.router.navigateByUrl('/employerportal/hospitallist')
    }

  }
}
