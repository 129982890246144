import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { ApiServiceService } from '../providers/api-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CommonService } from '../common.service';
@Injectable({
  providedIn: 'root'
})
export class LoginService {


  loginStage: String = "";
  error: any;
  otp: any;
  timeprop: any;
  countertime: any;
  loginForm: any;
  isLoader: boolean = false;
  authToken: any
  constructor(private common: CommonService, public FormBuilder: FormBuilder, public api: ApiServiceService, public toaster: ToastrService, private router: Router) {
    this.loginForm = this.loadform()
  }

  ValidateEmail(mail: any) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      this.counter()
      return true
    }
    if (!mail) {
      this.error = "Please Enter your Email"
      return false
    }
    this.error = 'Enter a valid email'
    return false
  }
  resendOtp() {
    this.counter()
  }
  counter() {
    var time_limit = 30;
    clearInterval(this.timeprop)
    this.timeprop = setInterval(() => {
      if (time_limit == 0) {
        time_limit = 0
        this.countertime = time_limit
      } else {
        if (time_limit < 10) {
          time_limit = 0 + time_limit;
        }
        this.countertime = time_limit
        time_limit -= 1;
      }
    }, 1000);
  }
  loadform() {
    return this.FormBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(6)]],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')] /** [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]**/],
      password: ['', [Validators.required]],
      loginTc: [true, [Validators.requiredTrue]]
    })
  }



  async userLogin(stage: any, val: any) {
    this.isLoader = true
    let email = val?.email.value
    let otp = val?.otp.value
    let password = val?.password.value
    switch (stage) {
      case "email":

        let email_obj = {
          email: email,
          otp: true
        }
        this.api.login(email_obj).subscribe(resp => {
          if (resp.status == true) {
            this.toaster.success(resp.data, 'Success', {
              timeOut: 3000, closeButton: true,
            });
            this.loginStage = "email_otp"
            this.counter()
          }
          this.isLoader = false
        }, (err) => {
          this.common.statusCodeCheck(err)
          this.isLoader = false
        })
        break;

      case "otp_verify":
        let otp_obj = {
          email: email,
          otp: otp
        }
        this.api.getVerifyOTP(otp_obj).subscribe(async resp => {

          if (resp.status == true) {
            this.toaster.success("Loged in Successfuly ...", 'Success', {
              timeOut: 3000, closeButton: true,
            });
            this.authToken = resp?.data?.token
            localStorage.setItem('token', this.authToken)
            await this.common.profiledetails('Initial')
          } else {
            this.toaster.error(resp.error, 'Failed', {
              timeOut: 3000, closeButton: true,
            });

          }
          this.isLoader = false
        }, (err) => {
          this.common.statusCodeCheck(err)
          this.isLoader = false
        })
        break;

      case "pass_verify":
        let pass_obj = {
          email: email,
          password: password
        }
        this.api.login(pass_obj).subscribe(async resp => {
          if (resp.status == true) {
            this.toaster.success("Logged in Successfuly", 'Success', {
              timeOut: 3000, closeButton: true,
            });
            this.authToken = resp?.data?.token
            localStorage.setItem('token', this.authToken)
            await this.common.profiledetails('Initial')
          }
          this.isLoader = false
        }, (err) => {
          this.common.statusCodeCheck(err)
          this.isLoader = false
        })

        break;

      case "forgot-pass":
        let forgot_obj = {
          email: email
        }
        this.api.forgotPassword(forgot_obj).subscribe(async resp => {
          if (resp.status == true) {
            this.toaster.success(resp.data, 'Success', {
              timeOut: 3000, closeButton: true,
            });
          }
          this.isLoader = false
        }, (err) => {
          this.common.statusCodeCheck(err)
          this.isLoader = false
        })

        break;


      default:
    }
  }

  async newUserResetPassword(val: any, token: any) {
    localStorage.setItem('token', token)
    this.isLoader = true
    let reset_obj = {
      "newPassword": val
    }
    this.api.newUserResetPassword(reset_obj).subscribe(resp => {
      let profile = JSON.parse(localStorage.getItem('profiledetails')!)

      if (resp.status == true) {
        this.toaster.success(resp.data, 'Success', {
          timeOut: 5000, closeButton: true,
        });
        if (profile?.role == 'Employer') {
          this.router.navigateByUrl('/employerportal/dash');
        } else if (profile?.role == 'Employee') {
          this.router.navigateByUrl('/employeeportal/dash');
        } else if (profile?.role == 'admin') {
          console.log("inside");
          this.router.navigateByUrl('/admin')
        }
        this.isLoader = false
      }

    }, (err) => {
      this.common.statusCodeCheck(err)
      this.isLoader = false
    }).add(() => {
    })
  }
}
