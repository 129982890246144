import { Component } from '@angular/core';
import { CommonService } from '../../common.service';
import { ApiServiceService } from '../../providers/api-service.service';

@Component({
  selector: 'app-security-setting',
  templateUrl: './security-setting.component.html',
  styleUrls: ['./security-setting.component.scss']
})
export class SecuritySettingComponent {

  tick: any = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.608 240.608" xml:space="preserve">
<path d="m208.789 29.972 31.819 31.82L91.763 210.637 0 118.876l31.819-31.82 59.944 59.942L208.789 29.972z"
fill="#fa9531" />
</svg>`

  currentPassword: any = '';
  confirmPassword: any = '';
  newPassword: any = '';
  submitted: any = false;
  isPassword: any = true;
  isPassword1: any = true;
  isPassword2: any = true;
  inputType: any = 'password';
  inputType1: any = 'password';
  inputType2: any = 'password';
  getIsLoader:boolean =false;
  changePassErrorMsg: any = '';



  constructor(public common:CommonService,public api :ApiServiceService){}

  async changePassword() {
    if (this.validateForm()) {
      let json = { oldPassword: this.currentPassword, newPassword: this.confirmPassword }
      if(json){
        
      this.getIsLoader = true
      await this.common.changeUserPassword(json)
      }
      this.getIsLoader = false

    }
  }

  validateForm() {
    this.submitted = true
    let bool = false
    if (this.currentPassword == '' || this.confirmPassword == '' || this.newPassword == '') {
      bool = false
      let errMsg = this.currentPassword == '' ? 'Current password' : this.confirmPassword == '' ? 'Confirm password' : 'New password'
      errMsg += ' cannot be empty'
      this.changePassErrorMsg = errMsg
    } else if (this.newPassword != this.confirmPassword) {
      bool = false
      this.changePassErrorMsg= 'Confirm password and new password should be same'
    } else if (this.checkPwd(this.confirmPassword) != 'ok') {
      bool = false
      this.changePassErrorMsg= this.checkPwd(this.confirmPassword)
    } else {
      bool = true
      this.changePassErrorMsg= ''
    }
    return bool;
  }

  checkPwd(str:any) {
    if (str.length < 6) {
      return "Password must contain min 6 characters";
    } else if (str.length > 16) {
      return "Password must contain max 16 characters";
    } else if (str?.search(/\d/) == -1) {
      return "Password must contain 1 number";
    } else if (str?.search(/[a-zA-Z]/) == -1) {
      return "Password must contain 1 letter";
    } else if (!(/[a-z]/.test(str))) {
      return "Password must contain 1 lowercase";
    } else if (!(/[A-Z]/.test(str))) {
      return "Password must contain 1 uppercase";
    } else if (!(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(str))) {
      return "Password must contain 1 special character";
    }
    return "ok";
  }

  handleInput() {
    this.changePassErrorMsg= ''
  }
  passShowHide(type:any) {
    if (type == 1) {
      this.isPassword = !this.isPassword
      if (this.inputType == 'password') {
        this.inputType = 'text'
      } else {
        this.inputType = 'password'
      }
    } else if (type == 2) {
      this.isPassword1 = !this.isPassword1
      if (this.inputType1 == 'password') {
        this.inputType1 = 'text'
      } else {
        this.inputType1 = 'password'
      }
    } else if (type == 3) {
      this.isPassword2 = !this.isPassword2
      if (this.inputType2 == 'password') {
        this.inputType2 = 'text'
      } else {
        this.inputType2 = 'password'
      }
    }

  }

}
