import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../common.service';
import { ApiServiceService } from '../providers/api-service.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
  token: any;
  userList: any = []
  currentPage: number = 1;
  itemsPerPage: number = 5;
  search: any = '';
  tablehr: any = ["User Name", "Email ID", "Policy ID", "Role", "OTP", "OTP Expiry Time", "Action"];




  constructor(public common: CommonService, private api: ApiServiceService, public toaster: ToastrService) {

  }
  ngOnInit() {
    this.getAllUser()
  }

  filterData() {
    let data = this.userList.reverse()
    return data.filter((post: any) => {
      return (post.user_name?.toLowerCase()?.includes(this.search?.toLowerCase())
        || post.email_id?.toString()?.includes(this.search)
        || post.policy_id?.toLowerCase()?.includes(this.search?.toLowerCase())
        || post.role?.toLowerCase()?.includes(this.search?.toLowerCase())
        || post.otp?.toLowerCase()?.includes(this.search?.toLowerCase())
        || post.otp_expiry_time?.toLowerCase()?.includes(this.search?.toLowerCase())
      );
    });
  }
  get totalPages(): number {
    let data = (this.search && this.search.length > 2) ? this.filterData() : this.userList;
    return Math.ceil(data?.length / this.itemsPerPage);
  }

  getTableData() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    let value = (this.search && this.search.length > 2) ? this.filterData() : this.userList
    return value?.slice(startIndex, endIndex);
  }
  changePage(newPage: number): void {
    this.currentPage = newPage;
  }
  setitemsPerPage(item: number): void {
    this.itemsPerPage = item;
  }
  adminMyaccount: any = [
    {
      header: 'My Account',
      menu: [

        { name: 'Logout' },
      ]
    }
  ]

  async getAllUser() {
    this.api.allUser().subscribe(resp => {
      if (resp.status == true) {
        this.userList = resp.data
        console.log(this.userList);
      }
    }, (err) => {
      this.common.statusCodeCheck(err)
    })
  }

  dashboardHeader() {
    let empType: any = JSON.parse(localStorage.getItem('profiledetails')!)
    if (empType.role == 'admin') {
      return this.adminMyaccount

    }

  }

  async deleteUser(email_id: string) {
    let json = { email_id }
    this.api.deleteUser(json).subscribe(resp => {
      if (resp.status == true) {
        this.toaster.success(resp.data, 'Success', {
          timeOut: 3000, closeButton: true,
        });
        this.getAllUser()
      }
    }, (err) => {
      this.common.statusCodeCheck(err)
    })

  }

  dashboardMyAccount() {
    let empType: any = JSON.parse(localStorage.getItem('profiledetails')!)
    if (empType.role == 'admin') {
      return this.adminMyaccount
    }
  }
  empAccDropDown: any = [
    { name: 'My Profile / Settings', router: '/employeeportal/myaccount' },
    { name: 'Logout', router: '/employeeportal/dash' },
    { name: 'v-0.0.01', router: '/employeeportal/dash' },
  ]
}


