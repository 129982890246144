import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent {
  @Input() subHeader:any
  @Input() header:any
  @Input() buttontext:any

  constructor(private router:Router){}

  subMenu:any=[
    {name:'Add an employee', router:'/employerportal/addemployee'},
    {name:'Upload Bulk Records', router:'/employerportal/bulkUpload'},
  ]
  buttonDropDown:boolean=false


  navigate(val:any){
    this.router.navigateByUrl(val)
  }
}
