import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

  public global = {
    BASEURL: 'https://mypolicy.cloware.in/api/v2/',
    URL: 'https://digital.insillion.com/api/v1/auth',
    endpoint: {
      userLogin: 'user/',
      common: '',
      endorsement: 'endorsement/',
      employees: 'employees/',
      hospital: 'hospitals/',
      policyBenefits: 'policybenefits/',
      cdBalance: 'deposit/',
      claim: 'claim/',
      admin: 'admin/',
      deleteUser: 'deleteuser/'
    }
  }

  getHeaders() {
    let token = localStorage.getItem('token');
    if (token) {
      return {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      }
    } else {
      return {
        headers: new HttpHeaders({
          'Authorization': `Bearer `
        })
      }
    }
  }

 

  constructor(public HTTPS: HttpClient,) { }

  login(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.userLogin}login`, payload);
  }

  getVerifyOTP(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.userLogin}verify-otp`, payload);
  }
  getforgetPassword(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.userLogin}forget-password`, payload);
  }
  getresetPassword(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.userLogin}reset-password`, payload);
  }
  // getresetPasswordToNewUser(payload:any) : Observable<any> {
 
  getpolicydetails(payload: any): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.common}policydetails?policy_id=${payload}`, this.getHeaders());
  }
  getProfiledetails(): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.common}profile`, this.getHeaders());
  }

  // view dropdown List 
  getDropdownList(): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.endorsement}dropdown`, this.getHeaders());
  }

  requestEndorsement(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.endorsement}employee`, payload, this.getHeaders());
  }

  documentUpload(payload: {desc: any; uw_id: any; Files: FormData;}): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.endorsement}documentUpload?uw_id=${payload.uw_id}&description=${payload.desc ? payload.desc :'proof'}`, payload.Files, this.getHeaders());
  }

  changePassword(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.userLogin}change-password`, payload, this.getHeaders());
  }

  // employer
  createEmployees(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.employees}createEmployees?policy_id=${payload.policy_id}`, payload.file, this.getHeaders());
  }
  endorsemenTotalStatustList(): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.endorsement}list`, this.getHeaders());
  }
  approveOrRejectEndorsment(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.endorsement}uw`, payload, this.getHeaders());
  }

  addOrEditEmployee(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}api/endorsement`, payload, this.getHeaders());
  }
  CalcService(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.endorsement}calc`, payload, this.getHeaders());
  }
  paymentService(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.endorsement}payment`, payload, this.getHeaders());
  }
  addEmployee(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.employees}createEmployees?policy_id=${payload.policy_id}`, payload.file, this.getHeaders());
  }

  // forgot Password
  forgotPassword(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.userLogin}forget-password`, payload, this.getHeaders());
  }

  // userlogin change password
  resetPassword(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.userLogin}reset-password`, payload);
  }

  // newUser login Reset password
  newUserResetPassword(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.userLogin}reset-password`, payload, this.getHeaders());
  }

  /**employeer 
   * get request
   */
  //employee list api used for download in xlsx format or in json response based on reuest qery params.
  employeeList(payload: any): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.common}employeeslist?${payload.download ? 'download=1&' : ''}${payload.dependant ? 'dependent_flag=true&' : ''}policy_id=${payload.policy_id}`, this.getHeaders());
  }

  downloadExcel(policy_id: any): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.common}downloadexcel?download=1&policy_id=${policy_id}`, this.getHeaders());
  }

  // Query List for Endorsement
  queryHistory(payload: any): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.endorsement}query/list?history_id=${payload}`, this.getHeaders());
  }

  employeeCount(policy_id: any): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.common}dashboard/employee-count?policy_id=${policy_id}`, this.getHeaders())
  }

  /**employeer 
   * post request
  */

  bulkUploadEmp(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.common}validate-sheet?type=${payload.type}`, payload.file, this.getHeaders());
  }

  //**createEmployees */
  employeeEndorsement(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.employees}createEmployees?policy_id=${payload?.policy_id}`, payload?.data, this.getHeaders());
  }

  //perform single endorsement for employee/dependants
  singleEndorsement(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.endorsement}single`, payload, this.getHeaders());
  }

  bulkEndorsement(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.endorsement}bulk`, payload, this.getHeaders());
  }

  // network Hospitals
  netHospitalDropDown(payload: any): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.hospital}dropdown?${payload.tpa ? `tpa=${payload.tpa}&` : ''}${payload.state ? `state=${payload.state}` : ''}`, this.getHeaders());
  }

  getNetHospital(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.hospital}?${payload.tpa ? `tpa=${payload.tpa}` : ''}${payload.hospital_name ? `&hospital_name=${payload.hospital_name}` : ''}`,payload.json, this.getHeaders());
  }

  // view Endorsement
  viewEndorsement(payload: any): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.endorsement}view?history_id=${payload}`, this.getHeaders());
  }

  // view endorsement Document List 
  getDocumentList(payload: any): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.endorsement}document/list?history_id=${payload}`, this.getHeaders());
  }

  // raiseQuery in view Endorsement
  raiseQueryRequest(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.endorsement}query?history_id=${payload.id}`, payload.remarks, this.getHeaders());
  }

  // policy Benefits api add and update
  addAndUpdate(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.policyBenefits}add?policy_id=${payload.id}${payload.edit == 'true' ? `&edit=${payload.edit}` : ''}`, payload.data, this.getHeaders());
  }

  // get list of policy benefits 
  getPolicyBenefitsList(payload: any): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.policyBenefits}list?policy_id=${payload}`, this.getHeaders());
  }

  // policy Benefits api add and update
  deletePolicy(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.policyBenefits}delete?policy_id=${payload.id}&s_no=${payload.s_no}`, {}, this.getHeaders());
  }

  // policy Benefits view api
  viewPolicy(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.policyBenefits}view?policy_id=${payload.id}&token=${localStorage.getItem('token')}&s_no=${payload.s_no}`, {}, this.getHeaders());
  }

  // getLedgerList
  getAllLedgerList(): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.cdBalance}ledger-details`, this.getHeaders());
  }

  // policy Benefits for download LedgerList
  downloadLedgerList(payload: any): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.cdBalance}download-ledgers?start-date=${payload.start_date}&end-date=${payload.end_date}`, this.getHeaders());
  }

  // policy Benefits add Cd balance
  addCdBalance(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.cdBalance}add-balance`, payload, this.getHeaders());
  }

  // Query document Upload
  uploadQueryDocument(history_id: any, file: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.endorsement}query/upload?query_id=${history_id}&description=proof`, file, this.getHeaders());
  }

  // upload Document for employee upload
  bulkUploadEmployee(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.endorsement}employee`, payload, this.getHeaders());
  }

  // upload Endorsement Document in Employee
  documentUploadEmployee(payload: { request_id: any; desc: any; Files: any; }): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.endorsement}documentUpload?history_id=${payload.request_id}&description=${payload.desc}`, payload.Files, this.getHeaders());
  }

    // Get ledger list data for cd balnce
    getLedgerData(payload: any): Observable<any> {
      return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.cdBalance}download-ledgers?start-date=${payload?.start_date}&end-date=${payload?.end_date}&download=0`, this.getHeaders());
    }

    policyHtmlfile(payload: any): Observable<any> {
      return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.policyBenefits}view?policy_id=${payload.id}&token=${localStorage.getItem('token')}&edit=${payload.edit}`);
    }
  /**
   * folowing api's are used in Claims module
   */

  // claim Status chart data/ recent claim list api
  getClaimChartData(): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.claim}latestlist`, this.getHeaders());
  }

  //claim list pending,reject,query,settled,approved
  getClaimList(): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.claim}list`, this.getHeaders());
  }

  //This Api to view the details of claim
  claimDetais(claim_id: any): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.claim}view?claim_id=${claim_id}`, this.getHeaders());
  }

  //This Api to view the list details of claim
  claimQueryList(claim_id: any): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.claim}query/list?claim_id=${claim_id}`, this.getHeaders());
  }

  //This Api to view the document list details of claim
  claimDocumentList(claim_id: any): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.claim}document/list?claim_id=${claim_id}`, this.getHeaders());
  }

  //Api used for claim on employee.
  employeeClaim(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.claim}intimate?emp_id=${payload.emp_id}`, payload.json, this.getHeaders());
  }

  //This Api for the users to upload documents details related to hospitals
  claimDocumentsUpload(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.claim}upload/claimdocuments?claim_id=${payload.claim_id}&document_desc=${payload.desc}`, payload.Files, this.getHeaders());
  }

  //This Api to raise query for employer and employee
  raiseClaimQuery(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.claim}query?claim_id=${payload.claim_id}`, payload.remarks, this.getHeaders());
  }

  //This Api to list the query details
  QueryDocumentUpload(payload: any, Files: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.claim}query/uploaddocument?query_id=${payload.query_id}&document_desc="description"}`, Files, this.getHeaders());
  }

  approveOrRejectClaim(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.claim}approve`, payload, this.getHeaders());
  }

  settleOrRejectClaim(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.claim}settle`, payload, this.getHeaders());
  }


  // Admin

  allUser(): Observable<any> {
    return this.HTTPS.get(`${this.global.BASEURL}${this.global.endpoint.admin}allUser`, this.getHeaders());
  }

  deleteUser(payload: any): Observable<any> {
    return this.HTTPS.post(`${this.global.BASEURL}${this.global.endpoint.deleteUser}`,payload, this.getHeaders())
  }

}


