import { Component } from '@angular/core';
import { CommonService } from './common.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'GMC';
  emp_type:any;
  navigationStart:any
  navigationEnd:any;
  constructor(public common:CommonService,public router:Router){
     console.log = () => {} // removing console log in out of development server.
      this.router.events.pipe(filter(event => event instanceof NavigationStart || event instanceof NavigationEnd)).subscribe((event) => {   
        if (event instanceof NavigationStart) {
          this.navigationStart = event;
        } else if (event instanceof NavigationEnd) {
          this.navigationEnd = event;
        }                 
         
        let token=localStorage.getItem('token')
        if(token && (this.navigationStart?.url?.split('/')[1] != "changepassword")){
          if(this.navigationEnd?.url == this.navigationStart?.url){
            let profiledetails = JSON.parse(localStorage.getItem('profiledetails')!);
            let policyDetails = JSON.parse(localStorage.getItem('policydetails')!);
            if (profiledetails && profiledetails.hasOwnProperty('policy_id')) {
              this.emp_type = profiledetails;
              this.common.profileData.emit(profiledetails);
              this.common.policyDetails = policyDetails[0];
              this.common.profileDetails = profiledetails;
              let dropDownlistData = JSON.parse(localStorage.getItem('dropDownlistData')!);
              if(dropDownlistData && dropDownlistData.hasOwnProperty('sum_insured_type')){
                this.common.dropDownlistData = dropDownlistData;
                this.common.updateDropdownList(dropDownlistData);
              } else {
                this.common.getDropdownList();
              }
            } else{
              this.common.profiledetails('refresh');
            }
          }
        }
      });
      
      this.initRouterListener();
  }
 

  private initRouterListener(): void {
    this.router.events
      .pipe(filter((event:any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.handleNavigation(event.urlAfterRedirects);
      });
  }

  private handleNavigation(url: string): void {    
    if (url !== '/' && !this.isValidSession() && this.navigationStart.url.split('/')[1] != 'changepassword') {
      this.router.navigate(['/']);
      this.clearSession();
    } else if (this.isValidSession() && url === '/' && this.router.url === '/') {
      if (this.emp_type?.role === 'Employer') {
      this.router.navigate(['/employerportal/dash']);
      } else if (this.emp_type?.role === 'Employee') {
      this.router.navigate(['/employeeportal/dash']);
      }
    } else if( this.navigationStart.url.split('/')[1] == 'changepassword'){
      // this.router.navigate(['/changepassword/:token'])
    }
  }

  isValidSession(): boolean {
    const userInputId = localStorage.getItem('token');
    return !!userInputId;
  }

  clearSession(): void {
    localStorage.clear();
  }

}

