<div class="w-full header-bg lg:px-4 sm:px-8 px-4">
    <div class="h-[70px] text-white mx-auto max-w-[1440px]">
        <div class="flex justify-between lg:justify-normal items-center w-full h-full">
            <header class="flex justify-between space-x-12 w-full items-center">
                
                <div class="cursor-pointer" (click)="navigateToHome()">
                    <img class="h-6 mr-4 xl:mr-12 w-[120px] cursor-pointer" src="../../../assets/images/inwhite.png" alt="">
                </div>
                <nav class="mx-auto flex max-w-7xl w-full items-center justify-between" aria-label="Global">
                    <div class="flex ml-auto md:hidden">
                            <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" (click)="openMenu = true">
                            <svg width="30px" height="30px" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 16.25C5.69036 16.25 6.25 15.6904 6.25 15C6.25 14.3096 5.69036 13.75 5 13.75C4.30964 13.75 3.75 14.3096 3.75 15C3.75 15.6904 4.30964 16.25 5 16.25Z" fill="#fff"></path><path d="M25.075 13.75H9.925C9.27607 13.75 8.75 14.2761 8.75 14.925V15.075C8.75 15.7239 9.27607 16.25 9.925 16.25H25.075C25.7239 16.25 26.25 15.7239 26.25 15.075V14.925C26.25 14.2761 25.7239 13.75 25.075 13.75Z" fill="#fff"></path><path d="M25.075 20H4.925C4.27607 20 3.75 20.5261 3.75 21.175V21.325C3.75 21.9739 4.27607 22.5 4.925 22.5H25.075C25.7239 22.5 26.25 21.9739 26.25 21.325V21.175C26.25 20.5261 25.7239 20 25.075 20Z" fill="#fff"></path><path d="M25.075 7.5H4.925C4.27607 7.5 3.75 8.02607 3.75 8.675V8.825C3.75 9.47393 4.27607 10 4.925 10H25.075C25.7239 10 26.25 9.47393 26.25 8.825V8.675C26.25 8.02607 25.7239 7.5 25.075 7.5Z" fill="#fff"></path></svg>
                            </button>
                    </div>
                    <div class="hidden md:flex md:gap-x-12">
                           <!-- <button [ngClass]="empType.role == 'Employer' ? '' :'hidden'"  type="button" class="flex items-center gap-x-1 text-sm font-semibold leading-6" (click)="navigateToEmployees()" aria-expanded="false">Employees</button> -->
                            <div class="relative" *ngFor="let item of headerData">
                                <button type="button"  (click)="onButtonFocus(item.id)" (blur)="onButtonBlur(item.id)" class="flex items-center justify-center gap-x-1 text-sm font-semibold leading-6" aria-expanded="false">
                                    {{item.name}}
                                    <svg class="h-5 w-5 flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                                <div [class.show]="item.isDropdownVisible" [ngClass]="{'transition':item.isDropdownVisible}" class="absolute left-[-50%] mt-6 dropdown z-10 cursor-pointer w-64 shrink rounded-xl bg-white text-sm leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                                    <div class="p-4 text-gray-900 cursor-pointer text-sm">
                                        <a class="block p-2 hover:text-blue-400" *ngFor="let headers of item.subMenu"  (mousedown)="navigate(headers.router, item.name)">{{headers.head}}</a>
                                    </div>
                                </div>
                            </div>
                            <button *ngIf="empType.role !== 'admin'" [ngClass]="empType.role == 'Employer' ? '' :''"  type="button" class="relative flex items-center justify-center gap-x-1 text-sm font-semibold leading-6" (click)="navigateToNetHospital()" aria-expanded="false">
                                Network Hospitals
                            </button>
                    </div>
                </nav>
                
                <div class="hidden md:flex md:gap-x-12">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
                      </svg>
                      
                    <div class="relative  h-full"  *ngFor="let item of myAccount">
                        <button type="button" (click)="myAccDropDown = !myAccDropDown" (blur)="myAccDropDown = false" class="flex items-center gap-x-1 text-sm h-full font-semibold leading-6" aria-expanded="false">
                            <div class="border rounded-full h-6 w-6 flex items-center justify-center">
                                <app-custom-icon [item]="'profile'" [color]="'none'" [stroke]="'#ffffff'" [style]="'width:15px;height:15px'"></app-custom-icon>
                            </div>
                            <span class="whitespace-nowrap">{{item.header}}</span>
                            <svg class="h-5 w-5 flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                            </svg>
                        </button>
                        <div class="absolute z-10 w-56 shadow-xl left-[-60%] mt-6 overflow-hidden rounded bg-white" [ngClass]="{'transition':myAccDropDown}" *ngIf="myAccDropDown">
                            <div class="p-4 text-gray-900 text-sm w-full">
                                <div  *ngFor="let submenu of item.menu" class="py-2 hover:text-[#243154] w-full">
                                    <p class="cursor-pointer hover:text-blue-400" (mousedown)="accountNavigation(submenu.router)" *ngIf="submenu.name == 'Policy Benefits'">{{submenu.name}}</p>
                                    <p class="cursor-pointer hover:text-blue-400" *ngIf="submenu.name == 'Logout'" (mousedown)="showDialog = true">{{submenu.name}}</p>
                                    <p class="cursor-pointer hover:text-blue-400" *ngIf="submenu.name == 'CD Balance'" (mousedown)="router.navigateByUrl(submenu.router)">{{submenu.name}}</p>
                                    <p *ngIf="submenu.name != 'Logout' && submenu.name != 'CD Balance' && submenu.name != 'Policy Benefits'">v-{{submenu.name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- Mobile menu, show/hide based on menu open state. -->
                <div class="md:hidden" role="dialog" aria-modal="true" *ngIf="openMenu">
                    <!-- Background backdrop, show/hide based on slide-over state. -->
                <div class="fixed inset-0 z-10"></div>
                    <div class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 xs:max-w-sm xs:ring-1 xs:ring-gray-900/10">
                        <div class="flex items-center justify-between">
                            <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" (click)="openMenu = false">
                                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div class="mt-6 flow-root">
                        <div class="-my-6 divide-y divide-gray-500/10">
                            <div class="space-y-2 py-6 border-b">
                                <div class="-mx-3"  *ngFor="let item of headerData">
                                    <button type="button" (click)="onButtonFocus(item.id)" (blur)="onButtonBlur(item.id)" class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" aria-controls="disclosure-1" aria-expanded="false">
                                        {{item.name}}
                                    <svg class="h-5 w-5 flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                                    </svg>
                                    </button>
                                    <div class="mt-2 space-y-2 primaryColor text-sm font-semibold px-4" id="disclosure-1" *ngIf="item.isDropdownVisible">
                                        <a class="hover:text-[#243154] block p-2" *ngFor="let headers of item.subMenu"  (mousedown)="navigate(headers.router, item.name)">{{headers.head}}</a>
                                    </div>
                                </div>
                                <div class="-mx-3 text-gray-900 py-2 pl-3 pr-3.5 text-base font-semibold" (click)="navigateToNetHospital()">
                                    Network Hospitals
                                </div>
                            </div>
                            <div class="py-6">
                            <a *ngFor="let item of myAccount[0].menu" class="-mx-3 primaryColor rounded-lg px-3 py-2.5 block text-sm font-semibold">
                                <p class="cursor-pointer" (mousedown)="accountNavigation(item.router)" *ngIf="item.name == 'Policy Benefits'">{{item.name}}</p>
                                <p class="cursor-pointer" *ngIf="item.name == 'Logout'" (mousedown)="showDialog = true">{{item.name}}</p>
                                <p class="cursor-pointer" *ngIf="item.name == 'CD Balance'" (mousedown)="router.navigateByUrl(item.router)">{{item.name}}</p>
                                <p *ngIf="item.name != 'Logout' && item.name != 'CD Balance' && item.name != 'Policy Benefits'">v-{{item.name}}</p>
                            </a>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
          <div *ngIf="showDialog" [ngClass]="{'transition':showDialog}" class="relative z-10"  aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
          
            <div class="fixed inset-0 z-10 overflow-y-auto">
              <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                      </svg>
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Log Out account</h3>
                      <div class="mt-2">
                        <p class="text-sm text-gray-500">Are you sure you want to logout?
                            </p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="button" class="button-app-bg-blue mx-2"  (click)="onConfirm()">Logout</button>
                    <button type="button" class="button-outlined-app-bg-blue"  (click)="onCancel()">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        
    </div>
</div>

