<div>
    <app-header [myAccount]="dashboardMyAccount()" [accDropDown]="adminMyaccount" [myAccount]="dashboardMyAccount()">
    </app-header>
    <!-- <div class="w-full h-[calc(100vh-70px)] overflow-auto app-bg-full section lg:px-4 sm:px-8 px-4 py-4">
        <div class="rounded-lg ring-1 ring-gray-300">
            <div class="inline-block min-w-full align-middle ">

                <div class="max-w-[1440px] mx-auto">
                    <table class="min-w-full divide-y divide-gray-300 select-none">
                        <thead>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-black text-gray-900">User Name
                            </th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-black text-gray-900">Email ID</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-black text-gray-900">Policy ID
                            </th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-black text-gray-900">Role</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-black text-gray-900">OTP</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-black text-gray-900">OTP Expiry
                                Time</th>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr class="relative" *ngFor="let item of userList">
                                <td class="relative py-5 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-0">{{item?.user_name
                                    ? item?.user_name : '--'}}</td>
                                <td class="px-3 py-5 text-sm text-gray-500 whitespace-nowrap">{{item?.email_id ?
                                    item?.email_id : '--'}}</td>
                                <td class="px-3 py-5 text-sm text-gray-500 whitespace-nowrap">{{item?.policy_id }}</td>
                                <td class="px-3 py-5 text-sm text-gray-500 whitespace-nowrap">{{item?.role ? item?.role
                                    : '--'}}</td>
                                <td class="px-3 py-5 text-sm text-gray-500 whitespace-nowrap">{{item?.otp ? item?.otp :
                                    '--'}}</td>
                                <td class="px-3 py-5 text-sm text-gray-500 whitespace-nowrap">{{item?.otp_expiry_time ?
                                    item?.otp_expiry_time : '--'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" (pageChange)="changePage($event)"
                (itemsPerPage)="setitemsPerPage($event)"></app-pagination>
        </div>
    </div> -->
    <div class="sm:mt-auto sm:flex-none">
        <div class="relative flex items-center mt-5">
          <input type="text" name="search" id="search" [(ngModel)]="search" placeholder="Search..."
            class="!h-10 block w-full rounded-md border-0 focus:outline-0 focus:border-[#243154] py-1.5 pl-2 pr-20 text-gray-900 shadow-sm ring-1 ring-gray-100 focus:ring-[#033365] sm:text-sm sm:leading-6" />
          <div class="absolute inset-y-0 right-0 flex items-center justify-center">
            <button class="h-full px-4 text-sm text-white rounded-r activeBg">Search</button>
          </div>
        </div>
      </div>
    <div class="card">
        <div class="flow-root">
            <div class="rounded-lg ring-1 ring-gray-300">
                <div class="inline-block min-w-full align-middle  ">
                    <table class="min-w-full divide-y divide-gray-300 !mt-0">
                        <thead>
                            <tr>
                                <th *ngFor="let item of tablehr"
                                    class="whitespace-nowrap text-sm font-semibold text-gray-900">
                                    {{ item }}</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white" *ngIf="userList">
                            <tr *ngFor="let item of getTableData(); let i=index">
                                <td class="whitespace-nowrap px-3 py-5 text-sm">
                                    {{item?.user_name
                                    ? item?.user_name : '--'}}</td>
                                <td class="px-3 py-5 text-sm font-medium text-gray-900 whitespace-nowrap">
                                    {{item?.email_id
                                    ? item?.email_id : '--'}}</td>
                                <td class="px-3 py-5 text-sm text-gray-900 whitespace-nowrap">{{ item.policy_id ?
                                    item.policy_id : '--' }}</td>
                                <td class="px-3 py-5 text-sm text-gray-500 whitespace-nowrap">{{item.role ? item.role :
                                    '--'
                                    }} </td>
                                <td class="px-3 py-5 text-sm text-gray-500 whitespace-nowrap">{{item.otp ? item.otp :
                                    '--' }}</td>
                                <td class="px-3 py-5 text-sm text-gray-500 whitespace-nowrap">{{item.otp_expiry_time ?
                                    common._.policy_date(item.otp_expiry_time) :
                                    '--' }}</td>
                                <td class="px-3 py-5 text-sm text-gray-500 whitespace-nowrap text-red-600 cursor-pointer" (click)="deleteUser(item?.email_id)">Delete</td>

                            </tr>
                        </tbody>
                        <tr *ngIf="userList?.length == 0">
                            <td colspan="9" class="text-center">
                                No Data Found
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" (pageChange)="changePage($event)"
                (itemsPerPage)="setitemsPerPage($event)" *ngIf="userList?.length != 0"></app-pagination>
        </div>
    </div>
</div>