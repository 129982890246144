import { EventEmitter, Injectable, Output } from '@angular/core';
import { ApiServiceService } from './providers/api-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  employeeListWithDependants: any
  employeeListWithoutDependants: any = {}
  employeeDetails: any = []
  policyDetails: any = {}
  profileDetails: any = {}
  selfData: any = [];
  NonSelfData: any = [];
  EmployeeCount: any;
  relationships: any;
  changePasswordData: any;
  endt_Status: any = []
  paymentDetails: any;
  profileLoader: boolean = false;
  csvData: string = ''
  dropDownlistData: any = {};
  reletionShips: any = [
    { text: "Employee", value: 'Self', gender: "male" },
    { text: "Spouse", value: "Spouse", gender: "female" },
    { text: "Spouse", value: "Spouse", gender: "male" },
    { text: "Son", value: "Dependant Children", gender: "male" },
    { text: "Daughter", value: "Dependant Children", gender: "female" },
    { text: "Father", value: 'Dependant Parents', gender: "male" },
    { text: "Mother", value: "Dependant Parents", gender: "female" }
  ];
  depReletionShips: any = [
    // { text: "Employee", value: 'Self' },
    { text: "Spouse", value: "Spouse", gender: "female" },
    { text: "Spouse", value: "Spouse", gender: "male" },
    { text: "Son", value: "Dependant Children", gender: "male" },
    { text: "Daughter", value: "Dependant Children", gender: "female" },
    { text: "Father", value: 'Dependant Parents', gender: "male" },
    { text: "Mother", value: "Dependant Parents", gender: "female" }
  ];
  annual_limit_type: any = [];
  annual_limit: any = [];
  TPA: any = [];
  location: any = [];
  designation: any = [];
  date: any = new Date();
  maxDate: any = this.date.toISOString().split('T')[0];
  isloader: boolean = false;
  ledgerList: any = [];
  employeeWithInactiveList: any = [];
  profileData: EventEmitter<any> = new EventEmitter();
  employee_List: EventEmitter<any> = new EventEmitter();

  constructor(public api: ApiServiceService, public toaster: ToastrService, private router: Router, public http: HttpClient,) { }

  isUndefined(value: any) {
    return (value == undefined || value === "" || value == null || value == "NaN" || value == "null" || value == "NULL");
  }
  public _ = {
    formatDateToString(date: any) {
      var fdate: any = new Date(date);
      if (fdate == "Invalid Date") {
        let [days, months, years] = date.split("-")
        fdate = new Date(`${years}-${months}-${days}`);
      }
      fdate = fdate.toLocaleDateString("en-IN")
      if (!fdate) return null;
      let [day, month, year] = fdate.split("/");
      if (day.length == 1) {
        day = '0' + day
      }
      if (month.length == 1) {
        month = '0' + month
      }
      return `${day}-${month}-${year}`;
    },
    removeSpace(event: any) {
      if (event?.code == "Space") {
        event.preventDefault();
      }
    },
    ageCalculate(birthday: string | number | Date) { // birthday is a date
      birthday = new Date(birthday)
      var ageDifMs = Date.now() - birthday.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },





    formatdateWithMonth(d: any) {
      console.log("date test", d);
      const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      // if (d) {
      //   d.split('T')
      //   d = new Date(d);
      //   let month = months[d.getMonth()];
      //   d = `${d.getDate()} ${month} ${d.getFullYear()}`
      //   return d
      // }
      if (d) {
        const dateParts = d.split("T");
        const date = new Date(dateParts[0]);

        const day = date.getDate();
        const suffix = getDaySuffix(day);
        const month = months[date.getMonth()];
        const formattedDate = `${day}${suffix} ${month} ${date.getFullYear()}`;
        return formattedDate;
      }


      function getDaySuffix(day: number) {
        if (day >= 11 && day <= 13) {
          return 'th';
        }
        switch (day % 10) {
          case 1:
            return 'st';
          case 2:
            return 'nd';
          case 3:
            return 'rd';
          default:
            return 'th';
        }
      }
    },
    dateToString: (date: any, med: any) => {
      if (!this.isUndefined(date)) {
        var dd = date.getDate();
        var mm = (date.getMonth() + 1);
        var yyyy = date.getFullYear();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        return (dd + med + mm + med + yyyy);
      } else {
        return "";
      }
    },
    generateNewId(emp_list: any, emp: any) {
      let dep_list = emp_list?.dependantsList;
      let new_dependent = JSON.parse(localStorage.getItem('newDependant')!);
      let arry: any[] = [];
      let latest_emp: any = '';
      let latest_dep: any = '';
      let result: any = '';
      if (emp == 'dep') {
        if (dep_list && dep_list.length > 0 && !new_dependent) {
          dep_list?.forEach((element: any) => {
            arry.push(element.member_emp_dependent_id);
          });
          latest_dep = arry[arry.length - 1];
          result = latest_dep.slice(0, -1) + (Number(latest_dep.at(-1)) + 1).toString();
        } else if (new_dependent && new_dependent.length > 0) {
          let arr = [...dep_list, ...new_dependent]
          arr?.forEach((element: any) => {
            arry.push(element.member_emp_dependent_id)
          });
          let latest_dep = arry[arry.length - 1]
          result = latest_dep.slice(0, -1) + (Number(latest_dep.at(-1)) + 1).toString();
        } else {
          result = `${emp_list?.member_emp_no}${'-01'}`;
        }
        return result
      } else if (emp == 'emp') {
        emp_list?.forEach((element: any) => {
          arry.push(element.member_emp_no);
        });
        // latest_emp = Math.max(...arry).toString()
        // result = latest_emp.slice(0, -1) + (Number(latest_emp.at(-1)) + 1).toString();
        latest_emp = arry.reduce((max, current) => {
          return max < current ? current : max
        })
        result = latest_emp.slice(0, -1) + (Number(latest_emp.at(-1)) + 1).toString();
        return result;
      }
    },
    setDobMaxDate() {
      var today: any = new Date();
      var dd: any = today.getDate() - 1; //to set previous date;
      var mm: any = today.getMonth() + 1; //January is 0!
      var yyyy: any = today.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm;
      }
      return yyyy + '-' + mm + '-' + dd
    },
    keyPress(event: any) {
      const pattern = /[0-9\+\-\ ]/;
      let inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode != 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
    },
    keyPressAlphaNumeric(event: any) {
      var inp = String.fromCharCode(event.keyCode);
      if (event.keyCode != 13) {
        if (/[a-zA-Z0-9\s]/.test(inp)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    },
    policy_date(policy_dt: any) {
      let d = new Date(policy_dt)
      d.setDate(d.getDate() + 1);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate() - 1).padStart(2, '0')
      const formattesDate = `${year}-${month}-${day}`

      return formattesDate
    },
    trimSpaceUsingForm(form: any, name: any) {
      let trimRGX = /^\s+|\s+$/g;
      let startRGX = /^ +/gm;
      let result = form[name].value.replace(startRGX, "");
      form[name].patchValue(result);
    },
    trimSpaceUsingNgmodel(component: any, model: any) {
      let trimRGX = /^\s+|\s+$/g;
      let startRGX = /^ +/gm;
      let result = component[model].replace(startRGX, "");
      component[model] = result;
    },
    timestampToIsdTimeandDate(date: any) {
      const utcTimestamp = new Date(date);
      const istOptions = { timeZone: 'Asia/Kolkata' }
      const istTime = utcTimestamp.toLocaleString('en-US', istOptions)
      return istTime
    }
  }


  async profiledetails(val: any) {
    this.profileLoader = true;
    this.api.getProfiledetails().subscribe({
      next: async resp => {
        if (resp.status == true) {
          this.profileData.emit(resp.data);
          this.profileDetails = resp.data;
          this.profileLoader = false;
          if (resp.data.hasOwnProperty('policy_id')) {
            localStorage.setItem('profiledetails', JSON.stringify(resp.data));

            await this.policyedetails(resp.data.policy_id);
            if (val == 'Initial') {
              await this.getEmployeelistWithDependants();
              await this.getDropdownList();
              if (resp.data?.role == 'Employee' && resp?.data?.first_bool != 1) {
                await this.router.navigateByUrl('/employeeportal/dash');
              } else if (resp.data?.role == 'Employer' && resp?.data?.first_bool != 1) {
                await this.router.navigateByUrl('/employerportal/dash');
              } else if (resp?.data?.first_bool == 1) {
                await this.router.navigateByUrl('/newuser');
                localStorage.removeItem('token');
              }
            }
          } else {
            localStorage.setItem('profiledetails', JSON.stringify(resp.data));
            if (resp.data?.role == 'admin' && resp?.data?.first_bool != 1) {
              await this.router.navigateByUrl('/admin');
            }  else if (resp?.data?.first_bool == 1) {
              await this.router.navigateByUrl('/newuser');
              localStorage.removeItem('token');
            }
          }
        }
      },
      error: err => {
        this.statusCodeCheck(err)
        this.profileLoader = false;
      },
    })
  }

  async policyedetails(policy_id: any) {
    if (policy_id) {
      this.api.getpolicydetails(policy_id).subscribe((resp: any) => {
        if (resp && resp.status == true) {
          localStorage.setItem('policydetails', JSON.stringify(resp.data))
          this.policyDetails = resp.data[0]
        }
      }, (err) => {
        this.statusCodeCheck(err)
      })
    }
  }

  async getDropdownList() {
    this.dropDownlistData = [];
    this.api.getDropdownList().subscribe({
      next: resp => {
        if (resp.status == true) {
          this.dropDownlistData = resp.data.dropdown;

          localStorage.setItem('dropDownlistData', JSON.stringify(this.dropDownlistData));
        }
      },
      error: err => {
        this.statusCodeCheck(err)
      },
      complete: () => { }
    }).add(() => {
      this.updateDropdownList(this.dropDownlistData && this.dropDownlistData.hasOwnProperty('sum_insured_type') ? this.dropDownlistData : {});
    })
  }

  updateDropdownList(data: any) {

    this.annual_limit_type = data.sum_insured_type && data.sum_insured_type.length > 0 ? data.sum_insured_type : ["Individual", "Family Floater"];

    this.annual_limit = data.sum_insured && data.sum_insured.length > 0 ? data.sum_insured : ["100000", "125000", "150000", "175000", "200000", "225000", "250000", "275000", "300000", "325000", "350000", "375000", "400000", "425000", "450000", "475000", "500000"];

    this.TPA = data.tpa && data.tpa.length > 0 ? data.tpa : ["Almadallah", "Nextcare", "Globemed"];

    this.location = data.location && data.location.length > 0 ? data.location : ["Abu Dhabi", "Dubai", "Sharjah", "Ajman", "Umm Al Quwain", "Rad Al Khaimah", "Fujairah"];

    this.designation = data.designation && data.designation.length > 0 ? data.designation : ["test"];

  }

  async getEmployeelistWithDependants() {
    let profiledetails = JSON.parse(localStorage.getItem('profiledetails')!)
    if (profiledetails.hasOwnProperty('policy_id')) {
      let json = {
        dependant: true,
        policy_id: profiledetails.policy_id
      }
      this.api.employeeList(json).subscribe({
        next: async resp => {
          if (resp.status == true) {
            let array: any = []
            if (this.profileDetails?.role == "Employee") {
              await this.setEmployeeList(resp.data)
              this.employeeListWithDependants = resp?.data

              this.employee_List.emit(resp.data);
            } else if (this.profileDetails?.role == "Employer") {
              await this.setEmployeeList(resp.data?.employees_list)
              this.employeeListWithDependants = resp.data?.employees_list
              this.employee_List.emit(resp.data?.employees_list);

              this.EmployeeCount = resp.data.count
            }
          }
        },
        error: err => {
          this.statusCodeCheck(err)
        },
        complete: () => {

        }
      }).add(() => {

      })
    }
  }

  async getEmployeelistWithoutDependants() {
    let profiledetails = JSON.parse(localStorage.getItem('profiledetails')!)
    if (profiledetails.hasOwnProperty('policy_id')) {
      let json = {
        dependant: false,
        policy_id: profiledetails.policy_id
      }
      this.api.employeeList(json).subscribe({
        next: resp => {
          if (resp.status == true) {
            this.employeeListWithoutDependants = resp.data?.employees_list
          }
        },
        error: err => {
          this.statusCodeCheck(err)
        },
        complete: () => {

        }
      })
    }
  }

  async downloadWithDependent() {
    let profiledetails = JSON.parse(localStorage.getItem('profiledetails')!)
    let token = localStorage.getItem('token')
    if (profiledetails.hasOwnProperty('policy_id') && token) {
      const headers = { 'Authorization': 'Bearer ' + token };
      const url: string = `${this.api.global.BASEURL}employeeslist?download=1&dependent_flag=true&policy_id=${profiledetails?.policy_id}`;
      this.http.get(url, { responseType: 'blob', headers }).subscribe((response: Blob) => saveAs(response, `employees.xlsx`));
    }
  }

  async downloadWithoutDependent() {
    let token = localStorage.getItem('token')
    let profiledetails = JSON.parse(localStorage.getItem('profiledetails')!)
    if (profiledetails.hasOwnProperty('policy_id') && token) {
      const headers = { 'Authorization': 'Bearer ' + token };
      const url: string = `${this.api.global.BASEURL}employeeslist?download=1&policy_id=${profiledetails?.policy_id}`;
      this.http.get(url, { responseType: 'blob', headers }).subscribe((response: Blob) => saveAs(response, `employees.xlsx`));
    }
  }

  /**
 * Method is use to download file.
 * @param data - Array Buffer data
 * @param type - type of the document.
 */

  downloadXlsx(jsonData: any[], filename: string): void {
    const wb = this.convertToXlsx(jsonData, 'employee');
    XLSX.writeFile(wb, filename + '.xlsx');
  }

  convertToXlsx(jsonData: any[], sheetName: string): XLSX.WorkBook {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    return wb;
  }

  async setEmployeeList(data: any) {
    this.employeeDetails = data
    this.selfData = []
    this.NonSelfData = []
    let relationships: any = []
    if (this.profileDetails?.role == "Employee") {
      this.employeeDetails.forEach((i: { showEdit: boolean; isEdit: boolean; status: string; member_relationship: string; firstNameModal: string; firstNameModalErr: string; genderModal: string; genderModalErr: string; dobModal: string; attachmentModal: string; }) => {

        i.status = ''
        if (i.member_relationship != '') {
          relationships.push(i.member_relationship)
        }

      });
      this.relationships = relationships
    }
    this.employeeDetails.forEach((i: { member_relationship: string; dependantsList: never[]; activeDependantsList: never[]; }) => {
      if (i.member_relationship?.toLowerCase() == 'self') {
        i.dependantsList = []
        i.activeDependantsList = []
      }
      i.member_relationship?.toLowerCase() == "self" ? this.selfData.push(i) : this.NonSelfData.push(i)
    });
    this.selfData.forEach((el: { isShowDependant: boolean; member_emp_no: any; dependantsList: { member_emp_no: any; active: null; }[]; activeDependantsList: { member_emp_no: any; active: null; }[]; }) => {
      el.isShowDependant = false
      this.NonSelfData.forEach((el1: { member_emp_no: any; active: null; }) => {
        if (el.member_emp_no == el1.member_emp_no) {

          el.dependantsList.push(el1)
          if (el1.active != 0) {
            el.activeDependantsList.push(el1)
          }
        }
      })
    })
    // }
    this.selfData = [...new Set(this.selfData)]
    this.NonSelfData = [...new Set(this.NonSelfData)]

  }

  async changeUserPassword(data: any) {
    this.api.changePassword(data).subscribe((resp: any) => {
      this.changePasswordData = resp.data
      this.toaster.success(resp.data, 'Success', {
        timeOut: 3000, closeButton: true,
      });
    }, (err) => {
      this.statusCodeCheck(err)
    }).add(() => {
      this.router.navigateByUrl('/employeeportal/dash');
    })
  }




  async payment(payload: any) {
    this.isloader = true;
    this.api.paymentService(payload).subscribe({
      next: resp => {
        if (resp.status == true) {
          this.toaster.success('Payment Completed', 'Successful', { timeOut: 5000, closeButton: true, });
          this.router.navigateByUrl('employerportal/employeerecords').catch(() => { })
        }
      },
      error: err => { this.statusCodeCheck(err); },
      complete: () => {
        this.removeUnwantedLocal();
        this.profiledetails('')
      }
    }).add(() => { this.isloader = false; })
  }

  //document upload 
  async getdocumentUpload(payload: any) {
    this.api.documentUpload(payload).subscribe((resp: any) => {
      this.toaster.success(resp.data, 'Success', {
        timeOut: 3000, closeButton: true,
      });
    }, (err) => {
      if (err) {
        this.statusCodeCheck(err)
      }
    }).add(() => {
    })
  }

  // document upload 
  async getdocumentUploadEmployee(payload: any) {
    this.api.documentUploadEmployee(payload).subscribe((resp: any) => {
      this.toaster.success(resp.data, 'Success', {
        timeOut: 3000, closeButton: true,
      });
    }, (err) => {
      if (err) {
        // this.statusCodeCheck(err)
      }
    }).add(() => { })
  }

  /***
  * method to perform get ledgerList
  ***/
  getAllLedgerList() {
    this.api.getAllLedgerList().subscribe({
      next: resp => {
        this.ledgerList = resp.data
      },
      error: err => {
        this.statusCodeCheck(err)
      }
    })
  }

  //method to get ledger list data for cd balance
  getLedgerList(val: any) {
    this.api.getLedgerData(val).subscribe({
      next: resp => {
        this.ledgerList = resp.data;
      },
      error: err => {
        this.statusCodeCheck(err);
      }
    })
  }

  // method for download ledger list
  async downloadLedgerList(val: any) {
    let token = localStorage.getItem('token')
    if (token) {
      const headers = { 'Authorization': 'Bearer ' + token };
      const url: string = `${this.api.global.BASEURL}deposit/download-ledgers?start-date=${val?.start_date}&end-date=${val?.end_date}&download=1`;
      this.http.get(url, { responseType: 'blob', headers }).subscribe((response: Blob) => saveAs(response, `Ledgers.xlsx`));
      this.isloader = false;
    }
  }

  //dowload excel for emplyee/employer
  async downloadexcel(val: any) {
    let token = localStorage.getItem('token')
    if (token) {
      const headers = { 'Authorization': 'Bearer ' + token };
      const url: string = `${this.api.global.BASEURL}downloadexcel?download=1&policy_id=${val}`;
      this.http.get(url, { responseType: 'blob', headers }).subscribe((response: Blob) => saveAs(response, `employee.xlsx`));
      this.isloader = false;
    }
  }





  // error Handling
  statusCodeCheck(error: any) {
    let status = error?.status
    let message = error?.error?.data ? error?.error?.data : error?.error?.error
    if (status == 502 || status == 404 || status == 500) {
      this.toaster.error("Something Went Wrong Please Try Again Later", 'Failed', { timeOut: 7000, closeButton: true, });
    } else if (status == 401) {
      this.toaster.error("Unauthorised", 'Failed', { timeOut: 7000, closeButton: true, });
      this.unauthorizeHandle('', '', 'logout')
    } else if (status == 400) {
      this.toaster.error(message, 'Failed', { timeOut: 7000, closeButton: true, });
    }
  }

  // error Handling
  unauthorizeHandle(header: any, error: any, type: any) {
    if (type == 'logout') {
      localStorage.clear()
      this.router.navigateByUrl('/').catch(() => { })
    }
  }

  //remove unwanted local items
  removeUnwantedLocal() {
    localStorage.removeItem('newDependant');
    localStorage.removeItem('add_employee');
    localStorage.removeItem('editemployee');
    localStorage.removeItem('exceljson');
    localStorage.removeItem('add_dependant_file');
    localStorage.removeItem('changeemployee');
  }
}


import { AbstractControl, ValidationErrors } from '@angular/forms';

export class NameWhiteSpace {
  static noSpaceAllowed(control: AbstractControl): ValidationErrors | null {
    // 
    console.log(control.value);
    console.log(control.value?.toString()?.indexOf(' '));
    console.log(control.value?.toString()?.indexOf(' ') >= 0);

    if ((control.value?.toString())?.indexOf(' ') >= 0) {
      return { noSpaceAllowed: true }
    }
    return null;

  }
}

