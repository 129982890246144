import { Component, Input } from '@angular/core';
import { EmployerService } from '../../employer/employer.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../common.service';
import { Router } from '@angular/router';
import { ApiServiceService } from '../../providers/api-service.service';

@Component({
  selector: 'app-dragand-drop',
  templateUrl: './dragand-drop.component.html',
  styleUrls: ['./dragand-drop.component.scss']
})
export class DragandDropComponent {
  @Input() where: any;
  @Input() header: any

  constructor(public empService: EmployerService, public toaster: ToastrService, private common: CommonService, private router: Router, private api: ApiServiceService) {
    this.create_emp_file = []
    this.empService.dragAndDropHideShow = false
  }

  get profile_details() { return this.common.profileDetails }
  create_emp_file: any = []
  bulkRecord: any = []
  docSuccess: boolean = false
  get loader() { return this.empService.employerLoader }

  onSelect(e: any) {
  
    if (this.where == "add_dependant") {
      this.create_emp_file.push(...e.addedFiles);
      var files = e.addedFiles;
      if (files && files.length == 0) {
        return;
      }
      files.forEach(async (file: { name: any; }) => {
        // Extract filename and extension
        const filename = file.name;
        const extension = filename.substring(filename.lastIndexOf(".")).toUpperCase();
        // Check if the file is a CSV
        if (extension === ".CSV") {
          this.csvFileToJSON(file);
        } else {
          this.toaster.error('Please attach the supported document in .csv format', 'Failed', {
            timeOut: 3000, closeButton: true,
          })
        }
      });
    } else if (this.where == "create_employee_bulk" || this.where == "endorsement" || this.where == "Modify_details") {
      this.create_emp_file.push(...e.addedFiles)
    }
  }

  onRemove(event: any) {
    this.create_emp_file.splice(this.create_emp_file.indexOf(event), 1);
    if (this.where == "add_dependant") {
      localStorage.removeItem('exceljson');
      localStorage.removeItem('add_dependant_file');
    }
  }

  onCancel() {
    this.empService.uploadEmp = false;
    this.empService.uploadSubmitbtn = false;
   
  }

  async uploadDoc() {
    if (this.where == "create_employee") {
      if (this.create_emp_file && this.create_emp_file.length != 0) {
        let formData = new FormData();
        formData.append('file', this.create_emp_file[0])
        this.empService.createEmployees(formData)
      }
    } else if (this.where == "bulk_upload") {
      let exceldoc: any = JSON.parse(localStorage.getItem('exceljson')!)
      if (exceldoc && exceldoc.length > 0) {
        exceldoc.pop()
        let params = exceldoc
        await this.empService.addOrDeleteEmployee({ json: params, where: "bulk_upload" }).then(() => { }).finally(() => { })
      } else {
      }
    } else if (this.where == "create_employee_bulk" || this.where == "endorsement" || this.where == "Modify_details") {
      let formData = new FormData();
      formData.append('file', this.create_emp_file[0]);
      if (!this.empService.dragAndDropHideShow) {
        this.empService.uploadbulkEmployee({ type: this.where == "create_employee_bulk" ? 'employee-creation' : 'endorsement', file: formData });
      } else {
        this.empService.uploadEmp = false;
      }
    } else if (this.where == "add_dependant") {
      if (this.create_emp_file[0]) {
        localStorage.setItem('add_dependant_file', this.create_emp_file[0]);
        this.router.navigateByUrl('employerportal/addDeleteEmp');
        this.empService.uploadEmp = false;
      }
    }
  }

  onSubmit() {
   
    if (this.profile_details.role == 'Employer') {
      if (this.empService.edFileUpload.sheetdata && this.empService.edFileUpload.sheetdata.length > 0 && (this.where == "endorsement" || this.where == "Modify_details")) {
        this.empService.uploadbulkEmployeejson(this.empService.edFileUpload.sheetdata, this.where)
      } else if (this.empService.edFileUpload.sheetdata && this.empService.edFileUpload.sheetdata.length > 0 && this.where == "create_employee_bulk") {
        this.empService.employerLoader = true
        let obj = {
          policy_id: JSON.parse(localStorage.getItem('profiledetails')!)?.policy_id,
          data: { data: [...this.empService.edFileUpload.sheetdata] }
        }
        this.api.employeeEndorsement(obj).subscribe({
          next: resp => {
            this.toaster.success(resp.data, 'Successfully modified', { timeOut: 6000, closeButton: true, });
          },error:err=>{
            this.common.statusCodeCheck(err)
          }
        }).add(() => {
          this.common.getEmployeelistWithDependants();
          this.empService.uploadEmp = false;
          this.empService.employerLoader = false;
          localStorage.removeItem('exceljson');
        })
      }
    } else if (this.profile_details.role == 'Employee') {
      if (this.empService.edFileUpload.sheetdata && this.empService.edFileUpload.sheetdata.length > 0 && (this.where == "endorsement" || this.where == "Modify_details")) {
        this.empService.uploadFileEmployee(this.empService.edFileUpload.sheetdata, this.where);
      }
    }
  }

  async csvFileToJSON(file: any) {
    try {
      var jsonData: any = [];
      var reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = function (e: any) {
        var headers = [];
        var rows: any = e.target.result.split("\r\n");
        for (var i = 0; i < rows.length; i++) {
          var cells = rows[i].split(",");
          var rowData: any = {};
          for (var j = 0; j < cells.length; j++) {
            if (i == 0) {
              var headerName = cells[j].trim();
              headers.push(headerName);
            } else {
              var key = headers[j];
              if (key) {
                rowData[key] = cells[j].trim();
              }
            }
          }
          if (i != 0) {
            jsonData.push(rowData);
          }
        }
        jsonData.pop()
        localStorage.setItem('exceljson', JSON.stringify(jsonData))
      }
    } catch (e) {
      console.error(e);
    }
  }

}
