import { Component } from '@angular/core';
import packageJson from '../../../package.json'

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {

  public version: string = packageJson.version;
  employeeHeader: any = [
    {
      name: 'Endorsements',
      id: 1,
      isDropdownVisible: false,
      subMenu: [
        { head: 'Addition / Deletion', router: '/employeeportal/addDeleteEmp' },
        { head: 'Modify Details', router: '/employeeportal/modifyStatus' },
        { head: 'Status Info', router: '/employeeportal/endorsements' }
      ]
    },
    {
      name: 'Claims',
      id: 3,
      isDropdownVisible: false,
      subMenu: [
        { head: 'Intimate', router: '/employeeportal/intimateclaim' },
        { head: 'Claim Status', router: '/employeeportal/claimstatus' }
      ]
    },
  ]
  employeeMyaccount: any = [
    {
      header: 'My Account',
      menu: [
        { name: 'Policy Benefits', router: '/employeeportal/policyBenefits' },
  
        { name: 'Logout' },
        { name: `${this.version}` }
      ]
    }
  ]
  employerHeader: any = [
    {
      name: 'Employees',
      id: 1,
      isDropdownVisible: false,
      subMenu: [
        { head: 'View Employees', router: '/employerportal/employeerecords' },
        { head: 'Addition / Deletion', router: '/employerportal/addDeleteEmp' },
        { head: 'Modify Details', router: '/employerportal/modifyStatus' },
        { head: 'Status Info', router: '/employerportal/endorsements' }
      ]
    },
    {
      name: 'Claims',
      id: 3,
      isDropdownVisible: false,
      subMenu: [
        { head: 'Intimate', router: '/employerportal/intimateclaim' },
        { head: 'Claim Status', router: '/employerportal/claimstatus' }
      ]
    },
  ]
  employerMyaccount: any = [
    {
      header: 'My Account',
      menu: [
        { name: 'Policy Benefits', router: '/employerportal/policyBenefits' },
        { name: 'CD Balance', router: '/employerportal/balanceCD' },
        { name: 'Logout' },
        { name: `${this.version}` }
      ]
    }
  ]

  dashboardHeader() {
    let empType: any = JSON.parse(localStorage.getItem('profiledetails')!)
    if (empType.role == 'Employee') {
      return this.employeeHeader
    } else if (empType.role == 'Employer') {
      return this.employerHeader
    }
  }
  dashboardMyAccount() {
    let empType: any = JSON.parse(localStorage.getItem('profiledetails')!)
    if (empType.role == 'Employee') {
      return this.employeeMyaccount
    } else if (empType.role == 'Employer') {
      return this.employerMyaccount
    }
  }

  // Employee Myaccount DropDown
  empAccDropDown: any = [
    { name: 'My Profile / Settings', router: '/employeeportal/myaccount' },
    { name: 'Logout', router: '/employeeportal/dash' },
    { name: 'v-0.0.01', router: '/employeeportal/dash' },
  ]
}
