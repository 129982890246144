import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { AuthGuardComponent} from "./auth-guard/auth-guard.component"
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AdminComponent } from './admin/admin.component'

const routes: Routes = [
  { path: '', loadChildren: () =>import('./login/login.module').then((m) => m.LoginModule)},
  { path: 'changepassword/:token', component:ChangePasswordComponent},
  { path: 'admin', component:AdminComponent},
  {
    path: "",
    component: MainComponent,
    canActivate: [AuthGuardComponent],
    children: [
      { path: "", redirectTo: "/employeeportal", pathMatch: "full"  },
      { path: 'employeeportal', loadChildren: () =>import('./employee/employee.module').then((m) => m.EmployeeModule),canActivate: [AuthGuardComponent]},
      { path: 'employerportal', loadChildren: () =>import('./employer/employer.module').then((m) => m.EmployerModule),canActivate: [AuthGuardComponent]},
      //ClaimsModule
      { path: 'employerportal', loadChildren: () =>import('./claims/claims.module').then((m) => m.ClaimsModule),canActivate: [AuthGuardComponent]},
      { path: 'employeeportal', loadChildren: () =>import('./claims/claims.module').then((m) => m.ClaimsModule),canActivate: [AuthGuardComponent]},
    ]
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
