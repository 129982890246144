import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() currentPage: any;
  @Input() totalPages: any;
  @Output() pageChange = new EventEmitter<number>();
  @Output() itemsPerPage = new EventEmitter<number>();
  initialItemPerPage: number = 5
  list: any = [5, 10, 15, 20, 25]

  setitemsPerPage(): void {
    if (this.initialItemPerPage) {
      this.itemsPerPage.emit(Number(this.initialItemPerPage));
    }
  }
  setPage(pageNumber: number): void {
    if (pageNumber >= 1 && pageNumber <= this.totalPages) {
      this.pageChange.emit(pageNumber);
    }
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.setPage(this.currentPage - 1);
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.setPage(this.currentPage + 1);
    }
  }

  firstPage(): void {
    if (this.currentPage <= this.totalPages) {
      this.setPage(1);
    }
  }

  lastPage(): void {
    if (this.currentPage < this.totalPages) {
      this.setPage(this.getPageNumbers().length);
    }
  }
  
  getPageNumbers(): number[] {
    const pageNumbers: number[] = [];
    for (let i = 1; i <= this.totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }
}
