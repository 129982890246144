import { EventEmitter, Injectable } from '@angular/core';
import { ApiServiceService } from '../providers/api-service.service';
import { CommonService } from '../common.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class EmployerService {

    endorsementData: any = {}
    filteredStatusList: any = {}
    employerLoader: boolean = false
    showDialog: boolean = false
    openCard: boolean = false
    uploadEmp: boolean = false
    editEmployeeShow: boolean = false
    modify_status: boolean = false
    dragAndDropHideShow: boolean = false
    edFileUpload: any = ''
    raiseQuery: boolean = false
    dependantsData: any = {}
    dep_stage: any = ''
    emp_stage: any = ''
    empEdit: boolean = false
    editEmployeeData: any = ''
    viewEndorsementDetails: any = ''
    viewEndorsementDocList: any = ''
    raisQueryDialog: boolean = false
    policyBenefitsListData: any = []
    policyLoader: boolean = false
    policyDoc: boolean = false
    deletePolicyPopup: boolean = false
    cdLoader: boolean = false
    addCDbal: boolean = false
    claimChartData: any = []
    uploadSubmitbtn: boolean = true
    questionArrary: any = []
    answerArray: any = []
    policyStage: any = ''
    previewPolicylink: any = ''
    raiseQueryTotal: any = []
    dependentDocument: any = []
    modifyUploadEmp: boolean = false
    queryLoader: boolean = false
    respondQueryDialog: boolean = false
    endorse_chart_data: EventEmitter<any> = new EventEmitter();
    count_chart_data: EventEmitter<any> = new EventEmitter();


    public addOrDeleteEmployeeData: any;
    public CalcServiceData: any = {}
    public paymentDialog: any = {
        isdialog: false,
        isSuccessdialog: false,
        paymentData: {
            premium_value: 0,
            endorsement_id: '',
            history_id: '',
            payment_id: '',
            CD_balance: 0,
            payable_amt: 0,
            tax_amount:0,
            payable_amount_without_tax:0,
        }
    }
    public update_premium: Boolean = true;
    policyBenefitHtml: EventEmitter<any> = new EventEmitter();
    financial_Endt: any = {
        endorsement_type: ["Financial Endorsement - Addition/Deletion of members"],
        member: [],
        changes: []
    }
    nonFinancial_Endt: any = { endorsement_type: ["Non Financial - Correction in Employee/Dependent Name"], member: [], changes: [] }
    singleEndorsementData: any;

    constructor(public common: CommonService, public api: ApiServiceService, public toaster: ToastrService, private router: Router, public http: HttpClient) {
    }
    get profile_details() { return this.common.profileDetails }
    get employee_list() { return this.common.employeeListWithDependants }

    async createEmployees(val: any) {
        this.employerLoader = true
        let json = {
            policy_id: this.profile_details?.policy_id,
            file: val
        }
        this.api.createEmployees(json).subscribe(resp => {
            if (resp.status == true) {
                resp.data.forEach((i: any) => {
                    if (i.status) {
                        this.toaster.success(i.data, 'Success', {
                            timeOut: 5000, closeButton: true,
                        });
                    } else if (!i.status) {
                        this.toaster.error(i.data, 'Success', {
                            timeOut: 5000, closeButton: true,
                        });
                    }
                });
            }

        }, (err) => {
            this.common.statusCodeCheck(err)
        }).add(() => {
            this.router.navigateByUrl('/employerportal/dash')
            this.employerLoader = false
            this.uploadEmp = false
        })
    }

    async addOrDeleteEmployee(payload: any) {
        this.employerLoader = true
        let data
        if (payload && payload.json) {
            if (payload.where == "delete") {
                data = payload.json
            } else if (payload.where == "edit_employee" || payload.where == "edit_dependant") {
                this.nonFinancial_Endt.member = [...payload.json[0].member]
                this.nonFinancial_Endt.changes = [...payload.json[0].changes]
                data = this.nonFinancial_Endt
            } else {
                this.financial_Endt.member = [...payload.json, ...this.employee_list]
                data = this.financial_Endt
            }

            this.api.addOrEditEmployee(data).subscribe((resp) => {
                this.addOrDeleteEmployeeData = resp.data[0]
                if (resp.data[0]?.message.toLowerCase() == 'endorsement success') {
                    // this.toaster.success(resp.data[0]?.message, 'Success', {
                    //     timeOut: 3000, closeButton: true,
                    // });
                    if (payload.where != "delete") {
                        this.financial_Endt.payable_amount = resp.data.payable_amount
                        this.CalcService(this.financial_Endt, resp.data)
                    }
                } else {
                    this.toaster.error(resp.data[0]?.message, 'Failure', {
                        timeOut: 3000, closeButton: true,
                    });
                }
                this.openCard = false
            }, (err) => {
                this.common.statusCodeCheck(err)
                this.openCard = false
            }).add(() => {
                if (payload.where == "add_employee") {
                    this.router.navigateByUrl('/employerportal/addDependant')
                } else if (payload.where == "add_dependant" || payload.where == "edit_dependant") {
                    this.router.navigateByUrl('/employerportal/dash')
                } else if (payload.where == "edit_employee") {
                    localStorage.removeItem('empEditData')
                    this.router.navigateByUrl('/employerportal/employeerecords')
                } else if (payload.where == "bulk_upload") {
                    localStorage.removeItem('exceljson')
                    this.router.navigateByUrl('/employerportal/dash')
                }
                this.employerLoader = false
            })
        }
    }

    async CalcService(params: any, value: any) {
        if (params) {
            params.endorsement_type = "Financial Endorsement - Addition/Deletion of members"
            this.api.CalcService(params).subscribe({
                next: resp => {
                    if (resp.status == true) {
                        this.setCalcServiceData(resp.data, value)
                        this.router.navigateByUrl('/employerportal/premium');
                        this.toaster.success('Make Payment to Complete Endorsement', 'Successful', { timeOut: 5000, closeButton: true, });
                    }
                },
                error: err => {
                    this.common.statusCodeCheck(err)
                },
                complete: () => {

                }
            })
        }
    }

    async setDialogStatus(payload: boolean) {
        this.paymentDialog.isdialog = payload
    }

    async setsuccessDialog(payload: boolean) {
        this.paymentDialog.isSuccessdialog = payload
    }

    async setCalcServiceData(data: any, value: any) {
        this.CalcServiceData = data
        this.paymentDialog.paymentData.premium_value = this.CalcServiceData?.premium_value;
        this.paymentDialog.paymentData.CD_balance = Number(this.profile_details?.cd_balance);
        this.paymentDialog.paymentData.payable_amt = value?.payable_amount;
        this.paymentDialog.paymentData.endorsement_id = value?.endorsement_id;
        this.paymentDialog.paymentData.history_id = value?.history_id;
        this.paymentDialog.paymentData.payment_id = value?.payment_id;
        this.paymentDialog.paymentData.tax_amount = value?.tax_amount;
        this.paymentDialog.paymentData.payable_amount_without_tax = value?.payable_amount_without_tax;
    }

    async addEmployee(val: any) {
        let json = {
            policy_id: this.profile_details?.policy_id,
            file: val
        }
        this.api.addEmployee(json).subscribe(resp => {
            if (resp.status == true) {
                this.toaster.success(resp.data, 'Success', {
                    timeOut: 3000, closeButton: true,
                });
                this.router.navigateByUrl('/employerportal/dash')
            } else {
                this.toaster.error(resp.error, 'Failed', {
                    timeOut: 3000, closeButton: true,
                });
            }

        }, (err) => {
            this.common.statusCodeCheck(err)
        })
    }

    async getEndorsemnetList() {
        this.api.endorsemenTotalStatustList().subscribe(async resp => {
            this.endorsementData = resp?.data
            let statusList = ['rejected', 'query', 'pending', 'approved']
            let statusObj: any = {}
            statusList.forEach((el, id) => {
                statusObj[el] = []
            });
            if (this.endorsementData.length != 0) {
                await this.endorsementData.forEach((el: { status: any; }) => {
                    for (const key in statusObj) {
                        if (key == (el.status).toLowerCase()) {
                            statusObj[key].push(el)
                        }
                    }
                })
            }
            this.filteredStatusList = statusObj
            this.endorse_chart_data.emit(statusObj);

        }, (err) => {
            this.common.statusCodeCheck(err)
        })
    }

    async approveOrRejectEndoresement(val: any, type: any) {
        this.employerLoader = true
        this.api.approveOrRejectEndorsment(val).subscribe({
            next: resp => {
                if (resp.status == true) {
                    this.common.getEmployeelistWithDependants()
                    // this.toaster.success(val.approve == 0 ? resp.data : resp.data[0].message, 'Success', { timeOut: 4000, closeButton: true, });
                    this.getEndorsemnetList();
                    if (val.approve == 1 && type == "Financial Endorsement - Addition/Deletion of members") {
                        let obj = { member: [...this.employee_list],payable_amount:0 };
                        obj.payable_amount = resp.data[0].payable_amount
                        this.CalcService(obj, resp.data[0]);
                    }
                }
                this.raiseQuery = false
            },
            error: err => {
                this.common.statusCodeCheck(err)
            },
            complete: () => {

            }
        }).add(() => {
            this.showDialog = false;
            this.employerLoader = false;
        })
    }

    async uploadbulkEmployee(val: any) {
        this.edFileUpload = ''
        this.employerLoader = true
        let json = {
            type: val.type,
            file: val.file
        }
        this.api.bulkUploadEmp(json).subscribe(resp => {
            this.edFileUpload = resp?.data
            this.dragAndDropHideShow = true
            this.uploadSubmitbtn = false
        }, (err) => {
            if (err.status == 400) {
                this.uploadSubmitbtn = true
                this.edFileUpload = err?.error?.data
                this.dragAndDropHideShow = true
                // this.uploadEmp = false
                // this.base64ToFile(err?.error?.data?.file)
                this.common.downloadXlsx(err?.error?.data?.sheetData, 'ErrorSheet');
            } else {
                this.common.statusCodeCheck(err)
            }
        }).add(() => {
            this.employerLoader = false;
        })
    }

    base64ToFile(val: any) {
        const byteCharacters = atob(val);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: 'application/octet-stream' });
        const file = new File([blob], 'file', { type: 'application/octet-stream' });

        const url = URL.createObjectURL(file);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'file';

        document.body.appendChild(anchor);
        anchor.click();

        // Clean up
        document.body.removeChild(anchor);
        URL.revokeObjectURL(url);
    }

    /**
     * Method is use to Perform get QueryList.
     */
    getQueryList(history_Id: any) {
        if (history_Id) {
            let questArr: any = []
            let ansArr: any = []
            this.api.queryHistory(history_Id).subscribe(resp => {
                this.raiseQueryTotal = resp?.data
                resp.data.map((t: any) => {
                    if (t.type == 'question') {
                        questArr.push({"remarks":t.remarks, "c_ts": t.c_ts})
                    } else if (t.type == 'answer') {
                        ansArr.push({"remarks":t.remarks, "c_ts": t.c_ts})
                    }
                })
                this.questionArrary = questArr
                this.answerArray = ansArr
                this.raiseQuery = true
            }, (err) => {
                this.common.statusCodeCheck(err)
            })
        }
    }

    /**
   * Method is use to Perform single endorsement add/del/edit for employee/deppenant.
   */

    async emp_dep_SingleEndorsement(data: any, where: any) {
        this.common.isloader = true
        let payload: any;
        if (where == "financial_endt_del" || where == "financial_endt_add_dep" || where == "financial_endt_add_emp") {
            this.financial_Endt.member = [...data[0].member]
            this.financial_Endt.changes = [...data[0].changes]
            this.financial_Endt.endorsement_type = ["Financial Endorsement - Addition/Deletion of members"]
            payload = this.financial_Endt
        } else if (where == "Non_financial_endt") {
            this.nonFinancial_Endt.member = [...data[0].member]
            this.nonFinancial_Endt.changes = [...data[0].changes]
            this.nonFinancial_Endt.endorsement_type = ["Non Financial - Correction in Employee/Dependent Name"]
            payload = this.nonFinancial_Endt
        }
        this.api.singleEndorsement(payload).subscribe({
            next: resp => {
                if (resp.status == true) {
                    this.singleEndorsementData = resp.data
                    // this.toaster.success(resp.data.message, 'Success', { timeOut: 3000, closeButton: true, });
                    if (where != "Non_financial_endt") {
                        payload.payable_amount = resp.data.payable_amount
                        this.CalcService(payload, resp.data)
                    } else 
                    {
                        // this.toaster.success(resp.data.message, 'Success', { timeOut: 3000, closeButton: true, });
                        this.toaster.success('Endorsement is created successfully', 'Success', { timeOut: 3000, closeButton: true, });

                    }
                }
            },
            error: err => {
                this.common.isloader = false;
                this.common.statusCodeCheck(err);
            },
            complete: () => {
                if (this.dependentDocument && this.dependentDocument.length > 0) {
                    this.dependentDocument.forEach((element: any) => {
                        if (element.files) {
                            let formData = new FormData();
                            formData.append('Files', element.files);
                            let json = {
                                desc: element.desc,
                                uw_id: this.singleEndorsementData?.history_id,
                                Files: formData
                            }
                            this.common.getdocumentUpload(json);
                        }
                    });
                }
            }
        }).add(() => {
            this.common.isloader = false;
            this.common.getEmployeelistWithDependants();
            this.dependentDocument = [];
        })
    }

    /***
     * method to perform view Endorsement
     ***/
    viewEndorsement(history_Id: any) {
        if (history_Id) {
            this.api.viewEndorsement(history_Id).subscribe({
                next: resp => {
                    this.viewEndorsementDetails = resp?.data
                    this.viewEndorsementDetails.forEach((element: any) => {
                        element.new_data.forEach((i: any) => {
                            
                        });
                    })
                    this.raiseQuery = true
                    this.setEndorsementChangedData(this.viewEndorsementDetails);
                },
                error: err => {
                    this.common.statusCodeCheck(err)
                },
                complete: () => {

                }
            })
        }
    }

    setEndorsementChangedData(data: any) {
        if (data[0].old_data && data[0].old_data.length > 0) {
            for (const New of data[0].new_data) {
                    New.isNewRow = true;
                    data[0].old_data.forEach((old: any) => {
                        if ((old.member_emp_no == New.member_emp_no) && (old.member_emp_dependent_id == New.member_emp_dependent_id)) {
                            New.isID = (old?.member_relationship?.toString()?.toLowerCase() == 'self' || old?.member_relationship?.toString()?.toLowerCase() ==  'employee') ? (old?.member_emp_no != New?.member_emp_no ? true : false) : (old?.member_emp_dependent_id != New?.member_emp_dependent_id ? true : false)
                            New.oldID = New.isID ? (old?.member_relationship?.toString()?.toLowerCase() == 'self' || old?.member_relationship?.toString()?.toLowerCase() ==  'employee') ? old?.member_emp_no : old?.member_emp_dependent_id : ''
                            New.isName = old?.member_name?.toString()?.toLowerCase() != New?.member_name?.toString()?.toLowerCase() ? true : false;
                            New.oldName = New.isName ? old?.member_name : ''
                            New.isGender = old?.member_gender?.toString()?.toLowerCase() != New?.member_gender?.toString()?.toLowerCase() ? true : false;
                            New.oldGender = New.isGender ? old?.member_gender : ''
                            New.isDesignation = old?.member_degination?.toString()?.toLowerCase() != New?.member_degination?.toString()?.toLowerCase() ? true : false;
                            New.oldDesignation = New.isDesignation ? old?.member_degination : ''
                            New.isDob = old?.member_dob != New?.member_dob ? true : false;
                            New.oldDob = New.isDob ? old?.member_dob : ''
                            New.isLocation = old?.member_location?.toString()?.toLowerCase() != New?.member_location?.toString()?.toLowerCase() ? true : false;
                            New.oldLocation = New.isLocation ? old?.member_location : ''
                            New.isAnnualType = old?.annual_limit_type?.toString()?.toLowerCase() != New?.annual_limit_type?.toString()?.toLowerCase() ? true : false;
                            New.oldAnnualType = New.isAnnualType ? old?.annual_limit_type : ''
                            New.isAnnualLimit = old?.annual_limit?.toString()?.toLowerCase() != New?.annual_limit?.toString()?.toLowerCase() ? true : false;
                            New.oldAnnualLimit = New.isAnnualLimit ? old?.annual_limit : ''
                            New.isAvailableLimit = old?.available_limit?.toString()?.toLowerCase() != New?.available_limit?.toString()?.toLowerCase() ? true : false;
                            New.oldAvailableLimit = New.isAvailableLimit ? old?.available_limit : ''
                            New.isTpa = old?.tpa?.toString()?.toLowerCase() != New?.tpa?.toString()?.toLowerCase() ? true : false;
                            New.oldTpa = New.isTpa ? old?.tpa : ''
                            New.isDol = old?.member_emp_leaving_date?.toString()?.toLowerCase() != New?.member_emp_leaving_date?.toString()?.toLowerCase() ? true : false;
                            New.oldDol = New.isDol ? old?.member_emp_leaving_date : ''
                            New.isNewRow = false;
                        }
                    });
            }
        } else {
            data[0].new_data.forEach((element: any) => {
                element.isNewEmployee = true;
            });
        }
    }

    /***
     * method to perform get documentlist in view Endorsemane
     ***/
    getDocEndorsementList(history_Id: any) {
        if (history_Id) {
            this.api.getDocumentList(history_Id).subscribe(resp => {
                this.viewEndorsementDocList = resp.data
            }, (err) => {
                this.common.statusCodeCheck(err)
            })
        }
    }

    /***
     * method to perform raise Query Reques
     ***/
    raiseQueryRequest(history_Id: any, remarks: any, file: any) {
        this.queryLoader = true
        let raiseQueryData: any = {};
        let json = {
            id: history_Id,
            remarks: {
                remarks: remarks
            }
        }
        this.api.raiseQueryRequest(json).subscribe({
            next:async resp => {
                this.raisQueryDialog = false
                raiseQueryData = resp
                resp.status ? this.toaster.success(resp.data, 'Success', { timeOut: 5000, closeButton: true, }) : '';
                await this.getEndorsemnetList()

            },
            error: err => {
                this.raisQueryDialog = false
                this.common.statusCodeCheck(err)
            },
            complete: () => {
                if (file) {
                    this.uploadQueryDocument(raiseQueryData?.request_id, file)
                }
            }
        }).add(() => {
            this.respondQueryDialog = false;
            this.queryLoader = false;
            this.raiseQuery = false;

        })
    }

    /***
    * method to perform add and Update policy Benefits
    ***/
    addUpdatePolicyBenefits(val: any) {
        this.policyLoader = true
        if (val) {
            this.api.addAndUpdate(val).subscribe(resp => {
                this.getPolicyBenefitslist()
            }, (err) => {
                this.common.statusCodeCheck(err)
            }).add(() => {
                this.policyStage = ''
                this.policyDoc = false
                this.policyLoader = false
            })
        }
    }

    /***
    * method to perform get policy Benefits List
    ***/
    getPolicyBenefitslist() {
        let policy_id = JSON.parse(localStorage.getItem('profiledetails')!)
        let array: any = []
        this.api.getPolicyBenefitsList(policy_id?.policy_id).subscribe({
            next: resp => {
                
                this.policyBenefitsListData = resp.data
            },
            error: err => {
                this.common.statusCodeCheck(err)
            }
        })
    }

    /***
    * method to perform delete item from policy Benefits List
    ***/
    deletePolicyBenefitsList(val: any) {
        this.policyLoader = true
        this.api.deletePolicy(val).subscribe(resp => {
            this.toaster.success(resp.data, 'Success', {
                timeOut: 3000, closeButton: true,
            });
            this.getPolicyBenefitslist()
        }, (err) => {
            this.common.statusCodeCheck(err)
        }).add(() => {
            this.policyLoader = false
            this.deletePolicyPopup = false
        })
    }

    /***
    * method to perform view item from policy Benefits List
    ***/
    async viewPolicyBenefitsList(val: any) {
        this.policyLoader = true
        let token = localStorage.getItem('token')
        if (token) {
            const headers = { 'Authorization': 'Bearer ' + token };
            const url: string = `${this.api.global.BASEURL}policybenefits/view?policy_id=${val.id}&token=${localStorage.getItem('token')}${val.edit == 'true' ? `&edit=${val.edit}` : ''}`;
            this.http.get(url, { responseType: 'blob', headers }).subscribe({
                next: (resp: any) => {
                    if (val.edit == 'false') {
                        this.downloadPDF(resp)
                    }
                    
                },
                error: err => {
                    this.common.statusCodeCheck(err)
                }
            }).add(() => {
                this.policyLoader = false
            })
        }
    }

    //
    getPolicyHtmlfile(val: any) {
        this.api.policyHtmlfile(val).subscribe({
            next: resp => {
                this.policyBenefitHtml.emit(resp);
            },
            error: err => {
                this.policyBenefitHtml.emit(err.error?.text);
            },
            complete: () => {

            }
        })
    }

    // download PDF in view policy Benefits
    downloadPDF(val: any) {
        const url = window.URL.createObjectURL(val);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        if (this.policyStage != 'Edit') {
            link.setAttribute('download', 'view.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } else {
            this.previewPolicylink = link
        }
        window.URL.revokeObjectURL(url);
    }


    /***
  * method to perform download ledgerList
  ***/
    downloadLedgerList(val: any) {
        this.cdLoader = true
        this.api.downloadLedgerList(val).subscribe(resp => {

        }, (err) => {
            this.common.statusCodeCheck(err)
        }).add(() => {
            this.cdLoader = false
        })
    }

    /***
  * method to perform download ledgerList
  ***/
    addCDBalance(val: any) {
        this.cdLoader = true
        this.api.addCdBalance(val).subscribe(resp => {
            this.toaster.success('Funds Added Successfully', 'Success', {
                timeOut: 3000, closeButton: true,
            });
        }, (err) => {
            this.common.statusCodeCheck(err)
        }).add(() => {
            this.common.profiledetails('update')
            let stage = localStorage.getItem('stage')
            if (stage) {
                this.router.navigateByUrl('/employerportal/premium')
            } else {
                this.addCDbal = false
            }
            this.cdLoader = false

        })
    }

    /***
      * method to get Employee Count
     ***/
    getEmployeeCuount() {
        let policy_id = JSON.parse(localStorage.getItem('profiledetails')!)
        this.api.employeeCount(policy_id.policy_id).subscribe(resp => {
            this.count_chart_data.emit(resp.data);

        }, (err) => {
            this.common.statusCodeCheck(err)
        }).add(() => {

        })
    }


    /***
    * method to get Employee Count
   ***/
    getClaimChart() {
        this.api.getClaimChartData().subscribe(async resp => {
            this.claimChartData = resp.data
            let statusList = ['rejected', 'queries', 'pending', 'approved']

            let statusObj: any = {}
            statusList.forEach((el, id) => {
                statusObj[el] = []
            });
            if (this.claimChartData.length != 0) {
                await this.claimChartData.forEach((el: { status: any; }) => {
                    for (const key in statusObj) {
                        if (key == (el.status).toLowerCase()) {
                            statusObj[key].push(el)
                        }
                    }
                })
            }

        }, (err) => {
            this.common.statusCodeCheck(err)
        }).add(() => {

        })
    }



    async uploadbulkEmployeejson(payload: any, where: any) {
        let financial_Endt: any = {
            endorsement_type: [where == "endorsement" ? "Financial Endorsement - Addition/Deletion of members" : "Non Financial - Correction in Employee/Dependent Name"],
            member: [...payload],
        }
        this.api.bulkEndorsement(financial_Endt).subscribe({
            next: resp => {
                if (resp.status == true) {
                    if (where == "endorsement") {
                        financial_Endt.payable_amount = resp.data.payable_amount
                        this.CalcService(financial_Endt, resp.data)
                    } else
                    {
                    this.toaster.success("Endorsement is created Successfully", 'Success', { timeOut: 3000, closeButton: true, });
                    }
                }
            },
            error: err => {
                this.common.statusCodeCheck(err)
            },
        }).add(() => {
            this.uploadEmp = false;
            localStorage.removeItem('exceljson')
        })
    }

    uploadQueryDocument(history_Id: any, file: any) {
        this.api.uploadQueryDocument(history_Id, file).subscribe(resp => {

        }, (err) => {
            this.common.statusCodeCheck(err)
        }).add(() => {
            this.respondQueryDialog = false
            this.raiseQuery = false
        })
    }


    // upload bulk details in employee 
    async uploadFileEmployee(payload: any, where: any) {
        let financial_Endt: any = {
            endorsement_type: [where == "endorsement" ? "Financial Endorsement - Addition/Deletion of members" : "Non Financial - Correction in Employee/Dependent Name"],
            employee_array: [...payload],
        }
        this.api.bulkUploadEmployee(financial_Endt).subscribe({
            next: resp => {
                if (resp.status == true) {
                }
            },
            error: err => {
                this.common.statusCodeCheck(err)
            },
        }).add(() => {
            this.uploadEmp = false;
            localStorage.removeItem('exceljson')
        })
    }
}

