<div>
    <div class="flex justify-between">
        <span class="primaryColor text-[28px] font-bold">{{header}}</span>
        <div class="relative">
            <button class="bg-[#FFF3F1] app-text rounded-full text-sm px-4 h-10" *ngIf="buttontext && buttontext == 'Use my insurance'">{{ buttontext}}</button>
            
        </div>
    </div>
    <div>
        <div class="text-[14px] text-[#646464] pb-2" *ngIf="subHeader">{{ subHeader}}</div>
    </div>
</div>
