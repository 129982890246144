<div class="w-[100%]">
    <div  *ngIf="!empService.dragAndDropHideShow">
        <ngx-dropzone (change)="onSelect($event)" class="w-[100%] h-[200px] flex justify-center" [multiple]="false" >
            <ngx-dropzone-label class="primaryColor">
            <div class="flex space-x-8">
                    <div>
                        <app-custom-icon [item]="'fileUpload'" class="flex items-center justify-center ml-2"></app-custom-icon>
                        <span class="text-sm">Drag and Drop</span>
                    </div>
                    <div>
                        <span class="text-sm">OR</span>
                    </div>
                    <div>
                        <button class="text-xs tracking-wider h-10 px-4 activeText border-[#033365] border rounded">Browse</button>
                    </div>
            </div>
            </ngx-dropzone-label>
            <ngx-dropzone-preview *ngFor="let f of create_emp_file" [removable]="true" (removed)="onRemove(f)" [file]="f">
                <!-- <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label> -->
                <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>

            </ngx-dropzone-preview>
        </ngx-dropzone>
        <div class="flex justify-end mt-4 space-x-4 text-sm">
            <button class="border text-sm border-[#033365] text-[#033365] w-[240px] h-10 rounded" (click)="onCancel()" [disabled]="loader" >
                <span>Cancel</span>
            </button>
            <button class="bg-[#033365] text-sm text-white w-[240px] h-10 rounded disabled:bg-slate-300" (click)="uploadDoc()" [disabled]="loader" >
                <span *ngIf="!loader">Submit</span>
                <app-custom-icon *ngIf="loader" [item]="'loader'" [style]="'height:22px'"
                    class="flex items-center justify-center ml-2"></app-custom-icon>
            </button>
        </div>
    </div>
    <div *ngIf="empService.dragAndDropHideShow" class="flex space-x-2 items-center" >
<img width="100" height="90" class="cursor-pointer" src="../../../assets/images/alertIMG.png" alt="">
                <div class="">
                    Preview
                    <span class="px-1 text-green-400">{{empService.edFileUpload.totalEntries ?empService.edFileUpload.totalEntries:0}} found</span>
                    with
                    <span class="px-1 text-red-400">{{empService.edFileUpload.errorEntries ?empService.edFileUpload.errorEntries:0}} Error</span>
                </div>
            </div>
        <div  *ngIf="empService.dragAndDropHideShow" class="flex justify-end mt-4 space-x-4 text-sm">
            <button class="border text-sm border-[#033365] text-[#033365] w-[240px] h-10 rounded" (click)="onCancel()" [disabled]="loader">
                <span>Cancel</span>
            </button>
            <button class="bg-[#033365] text-sm text-white w-[240px] h-10 rounded disabled:bg-slate-300" (click)="onSubmit()" [disabled]="loader|| empService.uploadSubmitbtn" >
                <!-- || empService.uploadSubmitbtn -->
                <span *ngIf="!loader">Submit</span>
                <app-custom-icon *ngIf="loader" [item]="'loader'" [style]="'height:22px'"
                    class="flex items-center justify-center ml-2"></app-custom-icon>
            </button>
        </div>
</div>
