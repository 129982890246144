
     <div class="flex items-center justify-between">
    <div class="flex items-center justify-center">
        Items per page
        <div class="dropdown">
            <select class="disabled:bg-slate-100" [(ngModel)]="initialItemPerPage" (change)="setitemsPerPage()">
                <option *ngFor="let item of list" [value]="item" selected>{{item}}</option>
            </select>
        </div>
    </div>
    <div class="flex items-center justify-end p-2 space-x-4 pagination">    
        <button class="paginate-btn" (click)="firstPage()" >
            <svg fill="none" stroke="#033365" class="w-4 h-4" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"></path>
            </svg>
        </button>
        <button  (click)="prevPage()" [disabled]="currentPage === 1" class="paginate-btn">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#033365" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>              
        </button>
        <div>
            {{currentPage}} of {{totalPages}}
        </div> 
        
        <button  (click)="nextPage()" [disabled]="currentPage === totalPages" class="paginate-btn">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#033365" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>              
        </button>
        <button class="paginate-btn" (click)="lastPage()" >
            <svg fill="none" stroke="#033365"  class="w-4 h-4" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"></path>
            </svg>
        </button>
      </div>
</div>