import { Component } from '@angular/core';
import { CommonService } from '../../common.service';
import { EmployerService } from '../../employer/employer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cd-balance',
  templateUrl: './cd-balance.component.html',
  styleUrls: ['./cd-balance.component.scss']
})
export class CdBalanceComponent {
  date:any=new Date()
  months:any= ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  currentDate:any=` ${this.months[this.date.getMonth()]} ${this.date.getDate()}${','} ${this.date.getFullYear()}`


  constructor(private router: Router ,public common: CommonService, private employer: EmployerService){}

  get profile_details (){ return this.common.profileDetails}
  get policy_details() { return this.common.policyDetails }

}
