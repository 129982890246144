<div>
    <div class="card text-[#6E7795]">
        <div class="flex justify-between pb-4">
            <div>
                <p class="pb-2 text-sm">CD Balance</p>
                <div class="text-2xl font-semibold activeText pb-2">₹ {{ profile_details?.cd_balance ? profile_details?.cd_balance : '0' }}</div>
            </div>
            <div>
                <p class="text-sm text-646464 pb-2">As on {{currentDate}}</p>
            </div>
        </div>
        <div class="text-sm">
            <p class="pb-1">Policy ID</p>
            <p class="primaryColor">{{ profile_details?.policy_id ? profile_details?.policy_id : '0' }}</p>
        </div>
    </div>
    <div class="card space-y-4 text-sm mt-4 text-[#6E7795]">
                <p class="text-xl font-semibold primaryColor">Policy Details</p>
                <div class="policyHead">
                    <p class="pb-1">Policy Name</p>
                    <p class="primaryColor">{{policy_details?.product_name ?policy_details?.product_name:'--' }}</p>
                </div>
                <div class="grid grid-cols-2 policyHead">
                    <div>
                        <p class="pb-1">Policy ID</p>
                        <p class="primaryColor">{{ profile_details?.policy_id ? profile_details?.policy_id : '0' }}</p>
                    </div>
                    <div>
                        <p class="pb-1">Policy Status</p>
                        <p class="activeText">Active</p>
                    </div>
                </div>
                <div class="grid grid-cols-2 policyHead">
                    <div>
                        <p class="pb-1">Policy Start Date</p>
                        <p class="primaryColor">{{ common._.formatdateWithMonth(policy_details?.policy_start_date_dt) ? common._.formatdateWithMonth(policy_details?.policy_start_date_dt) : '--' }}</p>
                    </div>
                    <div>
                        <p class="pb-1">Policy End Date</p>
                        <p class="primaryColor">{{common._.formatdateWithMonth(policy_details?.policy_start_end_dt) ? common._.formatdateWithMonth(policy_details?.policy_start_end_dt) : '--'}}</p>
                    </div>
                </div>
                <div class="policyHead">
                    <div>
                        <p class="pb-1">Policy Type</p>
                        <p class="primaryColor">{{policy_details?.industry_type ? policy_details?.industry_type : '--'}}</p>
                    </div>
                </div>
    </div>
</div>
